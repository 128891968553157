import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import './Reservation.css'
import Loading from './Loading'
const Reservation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [GST_P_1, setGST_P_1] = useState(0);
    const [GST_P_2, setGST_P_2] = useState(0);
    const [GST_P_3, setGST_P_3] = useState(0);
    const [GST_P_4, setGST_P_4] = useState(0);
    const [roomtypeName, setroomtypeName] = useState("NA");
    const today = new Date();
    const [logroom, setLogroom] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [refreshData, setRefreshData] = useState(false)
    const api = "/reservation";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [AccCode, setAccCode] = useState([])

    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const Closeform = () => {
        window.location.assign("/Reservation")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "Reservation",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const getroombyid = (e) => {
        // console.log(`${hostlink}/rtmas/${e}`)
        axios.get(`${hostlink}/rtmas/${e}`)
            .then((resp) => {
                setroomtypeName(resp.data.rtname)
            })
            .catch(() => { setroomtypeName("NA") })

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)

    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}/reservation`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)

                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }
    const SaveData = async (e) => {
        setIsLoading(true);

        if (document.getElementById("1inputnamecheckin").value == "") {
            setIsLoading(false);
            toast.error("Please Select check in Date...")
            document.getElementById("1inputnamecheckin").focus();
            

            return false
        }


        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false };
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            setIsLoading(false);
            toast.error("Please Enter Mobile No")
            return false
        }

        try {
            if (document.getElementById("pagemode").innerHTML == ' Reservation [ NEW ]') {

                var ld = { ...savedata, roomtypename: roomtypeName, billamt: BillAmt, narration: usershopid + document.getElementById("1inputname").value.trim() + document.getElementById("inputname").value.trim() }
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        var ld = {
                            ...logdetail,
                            newdesc: `Res No : ${Response.data}, Mob No : ${gmob}, Name : ${gname},  Advance : ${advance}`,
                            operation: "New"
                        }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/Reservation")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Reservation already exists for this Date.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                console.log(savedata)
                var ld = { ...savedata, roomtypename: roomtypeName, billamt: BillAmt, narration: usershopid + document.getElementById("1inputname").value.trim() + document.getElementById("inputname").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = {
                                ...logdetail,
                                newdesc: `Res No : ${id}, Mob No : ${gmob}, Name : ${gname} , Advance : ${advance}`,
                                operation: "Edit"
                            }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/Reservation")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        resdate: today.toISOString().split('T')[0],
        chdate: "",
        gmob: "",
        gname: "",
        add1: "",
        add2: "",
        email: "",
        rtype: "",
        advance: "",
        discount: "",
        remarks: "",
        cname: "",
        stday: 1,
        mg: "",
        rent: 0,
        billamt: 0,
        fg: "",
        cg: "",
        paidby: "",
        documentno: "",
        gstno: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomtypename: "",
        acccode: 0
    })

    const { id, documentno, acccode, shopresno, gstno, roomtypename, resdate, rent, chdate, billamt, gmob, gname, add1, add2, email, rtype, advance, discount, remarks, cname, stday, mg, fg, cg, paidby } = savedata;
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`#$%^&*+{}|;:<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
        rentcalc()
    }
    const cmdEdit = async (e) => {
        setIsLoading(true);

        document.getElementById("inputname").focus();
        document.getElementById("cmdnew").disabled = true
        await axios.get(`${hostlink}/reservationsingle/${e}`)
            .then((Response) => {
                setPagemode(1);
                document.getElementById("pagemode").innerHTML = " Reservation [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                var roomname;
                x.style.display = "block"
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"

                setSavedata(Response.data);
                var ld = {
                    ...logdetail,
                    olddesc: `Res No : ${Response.data.id}, Mob No : ${Response.data.gmob}, Name : ${Response.data.gname}, Advance : ${Response.data.advance}`,
                    operation: "Delete"
                }
                setlogdetail(ld);
                setIsLoading(false);
                setDiscAmt(Number(Number(Response.data.rent * Number(Response.data.discount) / 100)))
                var billgst
                var stdays = Response.data.stday

                var currRent = (Number(Response.data.rent) - (Number(Number(Response.data.rent) * Number(Response.data.discount) / 100)))
                if (Number(GST_P_1) > Number(currRent)) {
                    billgst = "0"; setBillgstperc(0);
                } else if (Number(GST_P_2) > Number(currRent)) {
                    billgst = "5"; setBillgstperc(5);
                } else if (Number(GST_P_3) > Number(currRent)) {
                    billgst = "12"; setBillgstperc(12);
                } else if (Number(GST_P_4) > Number(currRent)) {
                    billgst = "18"; setBillgstperc(18);
                } else if (Number(GST_P_4) <= Number(currRent)) {
                    billgst = "28"; setBillgstperc(28);
                }
                setBillgstperc(billgst)
                setBillgstAmt((Number(currRent) * stdays) * billgst / 100)
                setBillAmt((Number(currRent) * stdays) + Number((Number(currRent) * stdays) * billgst / 100))
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"
        document.getElementById("pagemode").innerHTML = " Reservation [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
    }
    const deleteData = async (e, flname) => {

        try {

            await axios.get(`${hostlink}/reservationsingle/${e}`)
                .then((Responsenew) => {

                    var ld = {
                        ...logdetail,
                        newdesc: `Res No : ${Responsenew.data.id}, Mob No : ${Responsenew.data.gmob}, Name : ${Responsenew.data.gname}, Advance : ${Responsenew.data.advance}`,
                        olddesc: "",
                        operation: "Delete"
                    }
                    setlogdetail(ld);
                    saveLog(ld)
                    return false
                })
            console.log(`${hostlink}${api}/${e}`)
            await axios.delete(`${hostlink}${api}/${e}`)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/Reservation")
            }, 1000);
        } catch (error) { }
    }
    const [Billgstperc, setBillgstperc] = useState(0);
    const [BillgstAmt, setBillgstAmt] = useState(0);
    const [DiscAmt, setDiscAmt] = useState(0);
    const [BillAmt, setBillAmt] = useState(0);
    const rentcalc = () => {
        setDiscAmt(Number(Number(document.getElementById("22222inputname").value) * Number(document.getElementById("222inputname").value) / 100))
        var billgst
        var stdays = document.getElementById("22inputname").value

        var currRent = (Number(document.getElementById("22222inputname").value) - (Number(Number(document.getElementById("22222inputname").value) * Number(document.getElementById("222inputname").value) / 100)))
        // (((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100))
        if (Number(GST_P_1) > Number(currRent)) {
            billgst = "0"; setBillgstperc(0);
        } else if (Number(GST_P_2) > Number(currRent)) {
            billgst = "5"; setBillgstperc(5);
        } else if (Number(GST_P_3) > Number(currRent)) {
            billgst = "12"; setBillgstperc(12);
        } else if (Number(GST_P_4) > Number(currRent)) {
            billgst = "18"; setBillgstperc(18);
        } else if (Number(GST_P_4) <= Number(currRent)) {
            billgst = "28"; setBillgstperc(28);
        }
        setBillgstperc(billgst)
        setBillgstAmt((Number(currRent) * stdays) * billgst / 100)
        setBillAmt((Number(currRent) * stdays) + Number((Number(currRent) * stdays) * billgst / 100))
    }

    useEffect(() => {
        axios.get(`${hostlink}/shopmas/${usershopid}`)
            .then((res) => { console.log(res.data.gst5); setGST_P_1(res.data.gst5); setGST_P_2(res.data.gst12); setGST_P_3(res.data.gst18); setGST_P_4(res.data.gst28); })
            .catch(() => { })
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        document.getElementById("cmdnew").disabled = false
        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        axios.get(`${hostlink}/${usershopid}/reservation`)
            .then((Response) => {
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/${usershopid}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
                const firstrtcode = Response.data[0];

                axios.get(`${hostlink}/${usershopid}/accmas`)
                    .then((ResponseAcc) => {
                        setAccCode(ResponseAcc.data)
                        const firstAccode = ResponseAcc.data[0];
                        setSavedata({
                            ...savedata,
                            rtype: firstrtcode ? firstrtcode.id : null,
                            acccode: firstAccode ? firstAccode.id : null
                        });
                    })
                    .catch((ResponseAcc) => { "data Not Found" })
            })
            .catch((Response) => { "data Not Found" })

    }, [refreshData])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-book"></i> <span id='pagemode' > Reservation [ New ]</span></h5>
                <div className='row'>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Reservation Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='resdate' value={resdate} id="1inputname" /> <br />
                    </div>

                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Mob No</label>
                        <input type="tel" onChange={(e) => { onChangevalue(e) }} pattern="[0-9]*" title="Please enter a 10-digit mobile number" required maxLength="10" onBlur={(e) => { const inputValue = e.target.value; if (inputValue.length < 0) { toast.error("Please enter valid mobile number"); e.target.focus(); } }} onKeyPress={(e) => { if (e.target.value.length >= 10 || isNaN(Number(e.key))) { e.preventDefault(); } }} name='gmob' value={gmob} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Guest Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gname' value={gname} class="form-control" id="inputname1" /> <br />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Add 1</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add1' value={add1} class="form-control" id="inputname11" /> <br />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Add 2</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add2' value={add2} class="form-control" id="inputname111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Document No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='documentno' value={documentno} class="form-control" id="inputname1111" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Select Document</label>
                        <input type="file" class="form-control" id="documentpath" />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Email</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="inputname11111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">GST No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="inputname111111" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Company Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cname' value={cname} class="form-control" id="inputname1111111" /> <br />
                    </div>
                    {/*- Stay Details -------------------------------------*/}
                    <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Detail</span></h5>

                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Check In Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='chdate' value={chdate} id="1inputnamecheckin" /> <br />
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room Type</label>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e); getroombyid(e.target.value); }} name='rtype' value={rtype} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {RoomTYpe.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rtname} </option>
                                )
                            })}
                        </select>
                    </div>


                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Stay Days</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='stday' value={stday} class="form-control" id="22inputname" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Rent</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='rent' value={rent} class="form-control" id="22222inputname" />
                        <div style={{ fontSize: "12px", color: "blue" }}>
                            <table>
                                <tr ><td>Gst {Billgstperc} % </td><td> : {BillgstAmt}</td></tr>
                                <tr ><td>Final Bill Amt &nbsp;</td><td> : {BillAmt}</td></tr>
                            </table>
                        </div>
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Dis %</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='discount' value={discount} class="form-control" id="222inputname" />
                        <div style={{ fontSize: "12px", color: "blue" }}>
                            <table>
                                <tr ><td>Disc Amt</td><td> : {DiscAmt}</td></tr>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Male</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mg' value={mg} class="form-control" id="11111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Female</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='fg' value={fg} class="form-control" id="111111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Child</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='cg' value={cg} class="form-control" id="2inputname" /> <br />
                    </div>



                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Advance</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='advance' value={advance} class="form-control" id="2222inputname" /> <br />
                    </div>
                    {/* <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Paid By</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='paidby' value={paidby} class="form-control" id="22222inputname" /> <br />
                    </div> */}
                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Paid By</label>
                        <select id="acccode" onChange={(e) => { onChangevalue(e) }} name='acccode' value={acccode} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {AccCode.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.accname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <label className='font-monospace' class="form-label">Remarks</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='remarks' value={remarks} class="form-control" id="12inputname" /> <br />
                    </div>

                </div>


                <div class="col-md-12 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-door-open"></i> Reservation Details</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; Create New </button>
                    </div>
                    <div style={{ maxHeight: "100vh", overflow: "auto" }} className='col-12 '>
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" className="f-size-on-mobile">R.No</th>
                                    <th scope="col" className="hide-on-mobile">Res Date</th>
                                    <th scope="col" className="hide-on-mobile">Time</th>
                                    <th scope="col" className="f-size-on-mobile">Checkin <span className="hide-on-mobile" >Date</span> <span className="detail-on-mobile"> Details</span></th>
                                    <th scope="col" className="hide-on-mobile">Guest</th>
                                    <th scope="col" className="hide-on-mobile">Address</th>
                                    <th scope="col" className="hide-on-mobile">Mobile</th>
                                    <th scope="col" className="hide-on-mobile">Company</th>
                                    <th scope="col" className="hide-on-mobile">Remarks</th>
                                    <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (
                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}><th scope="row" className="f-size-on-mobile text-center" style={{ verticalAlign: 'middle', height: '100%' }}>{res.shopresno}</th>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.resdate ? new Date(res.resdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.restime ? new Date(`2022-01-01T${res.restime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}><span className="detail-on-mobile"><b> Check in Date : {res.chdate ? new Date(res.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b> </span>  <span className='hide-on-mobile'>{res.chdate ? new Date(res.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</span> <br />
                                            <div className="detail-on-mobile" style={{ fontSize: "12px", color: "blue" }}>
                                                Guest : {res.gname} <br /> Mob : {res.gmob} <br /> Room Type : {res.roomtypename} <br /> Room Rent with GST : {res.billamt} <br /> Advance : {res.advance}, <span style={{ color: "blue" }}> <b> Checkout Date : {res.resdate ? new Date(new Date(res.resdate).getTime() + ((res.stday) * 24 * 60 * 60 * 1000)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</b> </span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.gname}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.gmob}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.add1}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.cname}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.remarks}</td>
                                        <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <Link to={"/Reservation"} onClick={(e) => { cmdEdit(res.id) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                            <Link onClick={(e) => { handleOpenModal(res.id, res.shopresno) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Reservation No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid, deletemsg) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>

            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default Reservation
