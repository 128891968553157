import React, { useEffect, useRef, useState } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const Bom = () => {
    const roomCodeRef = useRef(null);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const usershopFGcode = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].fgcode : '0';
    const usershopRWcode = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].rwcode : '0';
    const scrollRef = useRef(null);
    const usershopidref = localStorage.getItem('MYPCKARTUSER')
        ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].hotelshopid
        : '0';

    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;

    
    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [editDeleteAPi, seteditDeleteAPi] = useState('');
    const [query, setQuery] = useState('');

    const [shopvno, setshopvno] = useState(0);
    const [data, setData] = useState([]);
    const [srvdata, setsrvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const inputRef = useRef(null);
    const usershopdeptcode = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].deptcode : '0'
    const queryConditionItem = usershopidref !== 0 ? `${usershopdeptcode}/itemmasterITG` : "itemmasterITG";
    const fetchData = async () => {
        const response = await fetch(`${hostlink}/${queryCondition}/${queryConditionItem}/${usershopRWcode}`);
        const jsonData = await response.json();
        setData(jsonData);
    };


    useEffect(() => {
        fetchData();
    }, []);

    const [selectedFincode, setSelectedFincode] = useState(0);
    const cmdEdit = (itemcode, shopvno) => {
        setisediting(true);
        setshopvno(shopvno);
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
        console.log(`${hostlink}/api/executeQuery?sqlQuery=select bommas.*,(select itname from itemmas where id = bommas.fincode and  shopid = ${queryCondition}) as fgitname,(select itname from itemmas where id = bommas.rawcode and  shopid = ${queryCondition}) as rwitname,(select unitname from unitmas where id = (select unitcode from itemmas where id = bommas.rawcode and shopid = ${queryCondition}) and shopid = ${queryCondition}) as rwitUnitName,(select unitname from unitmas where id = (select unitcode from itemmas where id = bommas.fincode and shopid = ${queryCondition}) and shopid = ${queryCondition}) as fgitUnitName from bommas where fincode = ${itemcode} and shopvno = ${shopvno} and shopid = ${usershopid}`)
        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select bommas.*,(select itname from itemmas where id = bommas.fincode and  shopid = ${queryCondition}) as fgitname,(select itname from itemmas where id = bommas.rawcode and  shopid = ${queryCondition}) as rwitname,(select unitname from unitmas where id = (select unitcode from itemmas where id = bommas.rawcode and shopid = ${queryCondition}) and shopid = ${queryCondition}) as rwitUnitName,(select unitname from unitmas where id = (select unitcode from itemmas where id = bommas.fincode and shopid = ${queryCondition}) and shopid = ${queryCondition}) as fgitUnitName from bommas where fincode = ${itemcode} and shopvno = ${shopvno} and shopid = ${usershopid}`)
            .then((resp) => {

                setguestdata({
                    ...guestdata,
                    c_GCHID: resp.data[0][2],
                    c_Gname: resp.data[0][3],
                    fgUnit: resp.data[0][20],
                });
                setitemdetail({
                    ...itemdetail,
                    fincode: resp.data[0][2],
                });


                const fetchedFincode = resp.data[0][2];  // Assume this is where fincode is located
                console.log("Fetched fincode:", fetchedFincode); // Debugging log

                // Set the new variable `selectedFincode` instead of directly updating the state
                setSelectedFincode(fetchedFincode);
                const newItemDetails = resp.data.map(existingItem => ({
                    rawcode: existingItem[8],
                    itname: existingItem[18],
                    rawname: existingItem[18],
                    barcode: existingItem[1],
                    discperc: 0,
                    qty: existingItem[6],
                    rate: existingItem[7],
                    gst: 0,
                    cess: 0,
                    rwUnit: existingItem[19],
                    itcomment: "",
                    isdiscountable: 0,
                }));
                // console.log(Response.data[0].kotMasDTO.tablename)     
                console.log(newItemDetails)
                setitemdetailGrid(newItemDetails);
                // onChangevalueRoom(resp.data[0][2],resp.data[0][3])
            })
    }
    useEffect(() => {
        // Update fincode state if needed
        setSelectedFincode(selectedFincode);
        console.log(selectedFincode)
    }, [selectedFincode]);  // This will update fincode whenever selectedFincode changes

    const cmdDelete = (itemcode, shopvno) => {

        axios.post(`${hostlink}/api/executeUpdate?sqlQuery=delete from bommas where fincode = ${itemcode} and shopvno = ${shopvno} and shopid = ${usershopid}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.ItemMaster.itname && item.ItemMaster.itname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const saveroomservice = async (isprint) => {
        document.getElementById("cmdsave").disabled = true
        if (c_GCHID == "0" || c_GCHID === undefined || c_GCHID === '') {
            toast.error("Please Select Finished Item...")
            document.getElementById("fincodefg").focus();
            document.getElementById("cmdsave").disabled = false
            return false
        }

        if (itemdetailGrid.length === 0) {
            toast.error("Nothing to Save. Add Item...")
            document.getElementById("cmdsave").disabled = false
            return false
        }
       await axios.get(`${hostlink}/api/executeQuery?sqlQuery=select * from bommas where shopid = ${usershopid} and fincode = ${fincode} and shopvno !=${shopvno}`)
            .then((response) => {
                if (response.data.length !== 0) {
                    toast.error("Item Name Already Exists...");
                    document.getElementById("cmdsave").disabled = false;
                    return;  // Stop further execution if record is found
                }
            
            
        document.getElementById("cmdsave").disabled = true
        console.log(c_Grcode)
        



        for (let i = 0; i < itemdetailGrid.length; i++) {
            itemdetailGrid[i].id = '';
            itemdetailGrid[i].fincode = c_GCHID;
            itemdetailGrid[i].finname = c_Gname;
            itemdetailGrid[i].shopid = usershopid;
            itemdetailGrid[i].fromshopid = usershopid;
            itemdetailGrid[i].ittotal = Number((((itemdetailGrid[i].qty * itemdetailGrid[i].rate)) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) + ((((itemdetailGrid[i].qty * itemdetailGrid[i].rate) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) * itemdetailGrid[i].gst / 100))).toFixed(2);
            itemdetailGrid[i].totqty = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0);
            itemdetailGrid[i].totordamt = Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0));
        }

        
        if (isediting === true) {
             axios.post(`${hostlink}/bommasedit/${shopvno}/${usershopid}`, itemdetailGrid)
                .then((resp) => {
                    toast.success("Save Sucess..");
                    setInterval(() => { window.location.assign("/Bom") }, 1000);
                }).catch((resp) => { console.log("Data Not Save..") })
        } else {
             axios.post(`${hostlink}/bommas`, itemdetailGrid)
                .then((resp) => {
                    toast.success("Save Sucess..");
                    setInterval(() => { window.location.assign("/Bom") }, 1000);
                }).catch((resp) => { console.log("Data Not Save..") })
        }
        return false;
    })
        .catch((error) => {
            document.getElementById("cmdsave").disabled = false
            toast.error("Something Else...");
            return false;
        });
    }
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.ItemMaster.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const [itemdetail, setitemdetail] = useState({ rawcode: 0, rwUnit: "", rawname: "", barcode: "", qty: 1, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
    const { rawcode, rawname, barcode, qty, rate, rwUnit, discperc, taxable, gst, gstamt, ittotal, fincode } = itemdetail;
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [itemdetailGrid]);

    useEffect(() => {

        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,fincode,(select itname from itemmas where id = bommas.fincode and  shopid = ${queryCondition}) as itname,vdate from bommas where shopid = ${usershopid} group by shopvno,fincode,vdate order by shopvno`)
            .then((Response) => {
                setsrvData(Response.data)
                setitemdetailGridRefresh(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [itemdetailGridRefresh]);

    const handleItemClick = (index) => {

        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].ItemMaster.itname);
        setitemdetail({
            ...itemdetail,
            rawcode: filteredData[index].ItemMaster.id,
            rawname: filteredData[index].ItemMaster.itname,
            barcode: filteredData[index].ItemMaster.barcode,
            rwUnit: filteredData[index].unitname,
            qty: 1,
            rate: filteredData[index].ItemMaster.restrate,
            discperc: 0,
            gst: 0,
        });
        document.getElementById("qty").focus();
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].ItemMaster.itname);

            setitemdetail({
                ...itemdetail,
                rawcode: filteredData[selectedIndex].ItemMaster.id,
                rawname: filteredData[selectedIndex].ItemMaster.itname,
                barcode: filteredData[selectedIndex].ItemMaster.barcode,
                rwUnit: filteredData[selectedIndex].unitname,
                discperc: 0,
                qty: 1,
                rate: filteredData[selectedIndex].ItemMaster.restrate,
                gst: 0,
            });
            document.getElementById("qty").focus();
        }
    };

    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
            event.target.setSelectionRange(0, event.target.value.length); // Select the input value
        }
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
            }
        }
    };

    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 0,
        resdate: today.toISOString().split('T')[0], checkindate: today.toISOString().split('T')[0], guestname: "", guestmob: "", guestemail: "", guestadd: "", guestadd2: "", guestcity: "", guestpincode: "", noofguest: 100, hallrent: 0, advanceamt: 0, refrence: "", remarks: "", payableamt: 0
    });
    const { fromdate, todate, resdate, checkindate, guestname, guestmob, guestemail, guestadd, guestadd2, guestcity, guestpincode, noofguest, hallrent, advanceamt, refrence, remarks, payableamt, status } = frmdata;
    const handleKeyDownFindItem = (event) => {

        const existingItem = data.find(item => item.ItemMaster.barcode === barcode);
        if (existingItem) {
            console.log("Item found:", existingItem);
            setQuery(existingItem.ItemMaster.itname)
            setitemdetail({
                ...itemdetail,
                rawcode: existingItem.ItemMaster.id,
                rawname: existingItem.ItemMaster.itname,
                barcode: existingItem.ItemMaster.barcode,
                rwUnit: existingItem.unitname,
                discperc: 0,
                qty: 1,
                rate: existingItem.ItemMaster.restrate,
                gst: 0,
            });
        } else {
            setitemdetail({
                ...itemdetail,
                id: 0,
            });
            return false
        }


        if (event.keyCode === 13 || event.keyCode === 9) {
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target);

            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus();
                console.log("1")

            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
                console.log("2")
            }
        }
    };
    const [guestdata, setguestdata] = useState({ c_Gname: "", fgUnit: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
    const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID, fgUnit } = guestdata;
    const onChangevalueRoom = (e, f) => {
        console.log("ON change room ")
        console.log(e)
        console.log(f)
        setitemdetail({
            ...itemdetail,
            fincode: e,
        });
        const existingItem = Room.find(item => item.ItemMaster.itname === f);
        if (existingItem) {
            console.log("find")
            setguestdata({
                ...guestdata,
                c_GCHID: existingItem.ItemMaster.id,
                c_Gname: existingItem.ItemMaster.itname,
                fgUnit: existingItem.unitname,
            });
        } else {
            console.log("Item not found");
        }
    };


    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setitemdetail({ ...itemdetail, [name]: sanitizedValue });
    }
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const [isVisible, setIsVisible] = useState(false);

    const [isediting, setisediting] = useState(false);

    const [Room, setRoom] = useState([])
    const [items, setItems] = useState([]);
    const handleDoubleClick = (itemId) => {
        const selectedItem = items.find(item => item.id === itemId);
        if (selectedItem) {
            alert(`Double-clicked on: ${selectedItem.ItemMaster.itname}`);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Show button when user scrolls down 400px
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        console.log(`${hostlink}/${queryCondition}/${queryConditionItem}/${usershopFGcode}`)
        axios.get(`${hostlink}/${queryCondition}/${queryConditionItem}/${usershopFGcode}`)
            .then((Response) => {
                setRoom(Response.data)
                
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
            
            axios.get(`${hostlink}/${queryCondition}/${queryConditionItem}/${usershopRWcode}`)
            .then((Response) => {
                setItems(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })


        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const handleDeleteItem = async (id) => {
        const updatedItDetails = itemdetailGrid.filter(item => item.rawcode !== id);
        toast.success("Item deleted");
        setitemdetailGrid(updatedItDetails);
        document.getElementById("inputname1111").focus();
    };

    const handleAddItem = () => {

        if (rawcode == 0) {
            toast.error("Please Select Item..");
            document.getElementById("inputname1111").focus();
            return false
        }
        const existingItem = itemdetailGrid.find(item => item.rawcode === rawcode);
        if (existingItem) {
            const updatedItDetails = itemdetailGrid.map(item => {
                if (item.rawcode === rawcode) {
                    return { ...item, qty: Number(Number(item.qty) + Number(qty)).toFixed(2) };
                }
                return item;
            });
            setitemdetailGrid(updatedItDetails);
            toast.success("quantity added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                barcode: "",
                qty: "",
                rwUnit: "",
                discperc: 0,
                rate: "",
                gst: "",
            });
            document.getElementById("inputname1111").focus();
        } else {
            setitemdetailGrid([...itemdetailGrid, {
                rawcode: rawcode,
                rawname: rawname,
                barcode: barcode,
                rwUnit: rwUnit,
                discperc: 0,
                qty: qty,
                rate: rate,
                gst: 0,
            }])
            toast.success("Item added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                rawcode: "",
                barcode: "",
                qty: "",
                rate: "",
                gst: "",
            });
            document.getElementById("inputname1111").focus();
        }
    };
    const cmdNew = () => {
        seteditDeleteAPi('')
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,fincode,(select itname from itemmas where id = bommas.fincode and  shopid = ${queryCondition}) as itname,vdate,vtime from bommas where shopid = ${queryCondition} group by shopvno,fincode,vdate,vtime order by shopvno`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/api/executeQuery?sqlQuery=SELECT shopvno, fincode, (SELECT itname FROM itemmas WHERE id = bommas.fincode AND shopid = ${queryCondition}) AS itname, vdate, vtime FROM bommas WHERE shopid = ${queryCondition} AND finname LIKE '%25${searchValue}%25' GROUP BY shopvno, fincode, vdate, vtime ORDER BY shopvno;`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    return (

        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-2 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-sliders"></i> BOM </h5>
                        </div>
                        <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search Room" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div>

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>
                            <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Create New </button>
                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="hide-on-mobile">S. No</th>
                                        {/* <th scope="col" className="hide-on-mobile">V Date</th> */}
                                        {/* <th scope="col" className="hide-on-mobile">V Time</th> */}
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Item Name</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0]}</td>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Item :</b> </span> {res[2]} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    <span>{res[0] !== '' && (<>V No : {res[0]} <br /></>)}</span>
                                                    {/* <span>{res[0] !== '' && (<>Item Name : {res[2]} <br /></>)}</span> */}
                                                </div>
                                            </td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                <Link to={"/Bom"} onClick={(e) => { cmdEdit(res[1], res[0]) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" ></span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                                {/* <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res[11]) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"></span></Link> &nbsp; */}
                                                {/* <Link className='btn btn-sm btn-outline-dark' onClick={(e) => { printdata(res[11]) }}><i class="fa-solid fa-file-invoice"></i> <span className="hide-on-mobile"></span></Link> &nbsp; */}
                                                <Link className='btn btn-sm btn-danger' onClick={(e) => { cmdDelete(res[1], res[0]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"></span></Link> &nbsp;
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Service */}
            <div id='newform' style={{ display: "none" }}>
                <div className='container-fluid py-1 '>
                    <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'white' }}>
                        <div className="col-md-3 align-self-center"><h4> &nbsp;<i class="fa-solid fa-sliders"></i> Bill of Materials (BOM) </h4></div>
                        <div class="col-5 align-self-center">
                        </div>

                    </div>
                    <p></p>
                    <div className="row">
                        <div className="col-md-12">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="inputEmail4">Finished Item</label>
                                    <select
                                        id="fincodefg"
                                        value={selectedFincode}  // Use the new variable here
                                        name="fincode"
                                        ref={roomCodeRef}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            const selectedText = e.target.selectedOptions[0].text;
                                            console.log("Selected value:", selectedValue);  // Debugging
                                            setSelectedFincode(selectedValue); // Temporarily set the new variable
                                            onChangevalueRoom(selectedValue, selectedText); // Handle room change logic
                                        }}
                                        className="form-select"
                                    >
                                        <option key={0} value={0}>Choose...</option>
                                        {Room.map((x, index) => (
                                            <option
                                                key={index}  // Unique key for each option
                                                value={x.ItemMaster.id}  // Ensure the value matches the expected field
                                                title={x.ItemMaster.id}
                                            >
                                                {x.ItemMaster.itname}  {/* Display item name */}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div class="form-group col-md-1">
                                    <label for="inputEmail4">Qty</label>
                                    <input type="Mobile" class="form-control" id="guestmob" name="guestmob" value="1" />
                                </div>
                                <div class="form-group col-md-1">
                                    <label for="inputEmail4">Unit</label>
                                    <input type="Mobile" class="form-control" id="fgUnit" name="fgUnit" value={fgUnit} />
                                </div>

                            </div>
                            <br />
                            <div className="row bg-dark align-self-center text-center text-bg-dark border border-1">
                                <div className="col-md-12"><h5 className='align-bottom text-center'><b>Select Raw Item</b></h5></div>
                            </div>

                            <div className="row py-1">
                                <div className="col-md-1"></div>
                                <div className="col-md-3">
                                    <label className='font-monospace' class="form-label">Item Name</label>
                                    <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} /> <br />
                                    {listVisible && filteredData.length > 0 && (
                                        <ul style={{}}>
                                            {filteredData.map((item, index) => (
                                                <li
                                                    key={item.ItemMaster.id}
                                                    onClick={() => handleSelectItem(item, index)}
                                                    onMouseDown={() => handleItemClick(index)}

                                                    className={index === selectedIndex ? 'selected' : ''}
                                                >
                                                    {item.ItemMaster.itname} - ₹{item.ItemMaster.restrate}
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                </div>
                                <div className="col-md-2">
                                    <label className='font-monospace' class="form-label">Barcode</label>
                                    <input type="text" value={barcode} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='barcode' class="form-control" id="barcode" /> <br />
                                </div>
                                <div className="col-md-1">
                                    <label className='font-monospace' class="form-label">Qty</label>
                                    <input type="text" value={qty} name='qty' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="qty" /> <br />
                                </div>

                                <div className="col-md-1">
                                    <label className='font-monospace' class="form-label">Unit</label>
                                    <input type="text" value={rwUnit} onKeyDown={handleKeyDownnext} name='rwUnit' class="form-control" id="rwUnit" /> <br />
                                </div>
                                <div className="col-md-1">
                                    <label className='font-monospace' class="form-label">Rate</label>
                                    <input type="text" value={rate} name='rate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="rate" /> <br />
                                </div>
                                {/* <div className="col-md-1">
                                    <label className='font-monospace' class="form-label">Dis %</label>
                                    <input type="text" value={discperc} name='discperc' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="discperc" /> <br />
                                </div> */}
                                <div id='addroomNew' className="col-md-2 text-center">
                                    <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                                    <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                                </div>

                            </div>

                            <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                                        <h6 style={{ "color": "blue", borderBottom: "1px solid black" }}>Item : {resp.rawname}</h6>
                                        <div className='col-9 text-start' style={{ fontSize: "15px", color: "black" }}>
                                            <table style={{ "width": "100%" }}>
                                                <tr><td>Qty </td><td>: {resp.qty}</td></tr>
                                                <tr><td>Rate</td><td>: {resp.rate}</td></tr>
                                                <tr><td>Gst {resp.gst} % </td><td>: {((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                                                <tr><td>Total</td><td>: {(resp.qty * resp.rate) + ((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                                            </table>
                                        </div>
                                        <div className='col-3 align-self-center'>
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>

                                    </div>
                                ))}
                            </div>

                            <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                                <div className='row text-left align-self-center p-1 m-0 '>
                                    <div className='col-md-5'>Item Name</div>
                                    <div className='col-md-2'>Barcode</div>
                                    <div className='col-md-1 text-end'>Qty</div>
                                    <div className='col-md-1 text-end'>Unit</div>
                                    <div className='col-md-1 text-end'>Rate</div>
                                    {/* <div className='col-md-1 text-end'>Dis %</div>
                                    <div className='col-md-1 text-end'>Dis Amt</div>
                                    <div className='col-md-1 text-end'>Gst</div>
                                    <div className='col-md-1 text-end'>Gst Amt</div> */}
                                    <div className='col-md-1 text-end'>Amount</div>
                                    <div className='col-md-1 text-end'>Action</div>
                                </div>
                            </div>
                            <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                                        <div className='col-md-5 align-self-center'>{resp.rawname}</div>
                                        <div className='col-md-2 align-self-center'>{resp.barcode}</div>
                                        <div className='col-md-1 align-self-center text-end'>{Number(resp.qty).toFixed(2)}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.rwUnit}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.rate}</div>
                                        {/* <div className='col-md-1 align-self-center text-end'>{resp.discperc}</div>
                                        <div className='col-md-1 align-self-center text-end'>{((resp.qty * resp.rate) * resp.discperc / 100).toFixed(2)}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.gst}</div>
                                        <div className='col-md-1 align-self-center text-end'>{(((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100).toFixed(2)}</div> */}
                                        <div className='col-md-1 align-self-center text-end'>{Number((((resp.qty * resp.rate)) - ((resp.qty * resp.rate) * resp.discperc / 100)) + ((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100))).toFixed(2)}</div>
                                        <div className='col-md-1 align-self-center text-end'>
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    </div>
                                ))}
                                <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                                    <div className='col-md-5 align-self-center'></div>
                                    <div className='col-md-2 align-self-center text-end'><b>Total :</b></div>
                                    <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0)).toFixed(2)}</b></div>
                                    {/* <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div> */}
                                    <div className='col-md-1 align-self-center  text-end'><b></b></div>
                                    <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((
                                        Number(((((resp.qty * resp.rate))) -
                                            (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) +

                                        Number((((resp.qty * resp.rate))) -
                                            (((resp.qty * resp.rate * resp.discperc) / 100)))

                                    )), 0)).toFixed(2)}</b></div>
                                    <div className='col-md-1 align-self-center'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 ">
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div className="parent-container">
                    {/* Fixed element at the bottom */}
                    <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

                        <div className="container-fluid" >

                            <div className="row">
                                <div className="col-md-9 f-size-on-mobile text-end p-1" style={{ borderRight: "1px solid black" }}>
                                    <p className='hide-on-mobile m-2'></p>
                                    {/* <button id='cmdsave' onClick={() => { saveroomservice(1) }} class="btn btn-primary">Save & Print</button>  &nbsp; */}
                                    <button id='cmdsave' onClick={() => { saveroomservice(0) }} class="btn btn-primary">Save</button>  &nbsp;
                                    <a href='/Bom' class="btn btn-outline-primary">Cancel</a>
                                </div>
                                {/* Summary */}
                                <div className="col-md-3 f-size-on-mobile ">
                                    <div className='rounded'>
                                        <div className="row p-1 m-1 f-size-on-mobile">
                                            {/* <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: "blue" }}> Rent :</div> <div style={{ fontFamily: 'arial', color: "blue", fontSize: '14px', fontWeight: 'bold' }} className="col-6 text-end">  {Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2)} </div> */}
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: "blue" }}> Round-off :</div> <div style={{ fontFamily: 'arial', color: "blue", fontSize: '14px', fontWeight: 'bold' }} className="col-6 text-end">  {Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2)} </div>
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))} </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default Bom