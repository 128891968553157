import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import numberToWords from 'number-to-words';

import './MessageBox.css'; // 
const RestBillView = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const IS_SHOW_GST_SUMMARY = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].showtaxsummary : '0';
    const fs_cname = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].fs_cname : '36';
    const printdata = async (e) => {
        try {
            console.log(`${hostlink}/RestbillviewOne/${usershopid}/${e}`)
            const response = await axios.get(`${hostlink}/RestbillviewOne/${usershopid}/${e}`);
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/RestbillviewOne/${usershopid}/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(data);
                  return false;
                }
                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();
    
                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 80mm;
                            margin: 0; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 80mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);
    
                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };
    
    

    

    const generatePrintContent = (data) => {
        let tableHTMLCESS = '';
        let tableHTML = '';
        if (IS_SHOW_GST_SUMMARY === 1) {
        const groupedData = {};
        // Group data by GST rate
        data.forEach(entry => {
            const gstRate = entry.SaleMasDTO.gst.toString().padStart(2, '0');
            if (!groupedData[gstRate]) {
                groupedData[gstRate] = [];
            }
            groupedData[gstRate].push(entry);
        });

        // Extract GST rates from the groupedData keys, convert them to numbers, and sort them
        const sortedGST = Object.keys(groupedData).map(Number).sort((a, b) => a - b);
        
        if (Object.keys(sortedGST).length > 0) {
            tableHTML = '<table style="width: 3in;"><tr style="vertical-align: middle;"><td style="width: 33%; text-align: center;"></td><td style="width: 34%; text-align: center;"><b>GST SUMMARY</b></td><td style="width: 33%; text-align: center;"></td></tr></table> <table border="1" cellspacing="0" cellpadding="0" style="width: 100%; text-align: center;"><tr><th>GST %</th><th>Taxable</th><th>CGST</th><th>SGST</th></tr>';

            // Iterate over sorted GST rates and generate table rows
            sortedGST.forEach(gstRate => {
                const gstRateStr = gstRate.toString().padStart(2, '0');
                const total = groupedData[gstRateStr].reduce((acc, entry) => acc + parseFloat(entry.SaleMasDTO.taxableamt), 0).toFixed(2);
                const cgst = Number((Number(total) * gstRate / 100) / 2).toFixed(2);
                const sgst = Number((Number(total) * gstRate / 100) / 2).toFixed(2);
                tableHTML += `<tr><td style="width: 30%; text-align: center;">${gstRateStr}%</td><td style="width: 30%; text-align: end;">${total}&nbsp;</td><td style="width: 20%; text-align: end;">${cgst}&nbsp;</td><td style="width: 20%; text-align: end;">${sgst}&nbsp;</td></tr>`;
            });

            tableHTML += '</table>';
            // Print the HTML table
            console.log(tableHTML);
        } else {
            // Print a message indicating no CESS data found
            console.log("No GST data found.");
        }
        const groupedDatacess = {};
        data.forEach(entry => {
            const cess = entry.SaleMasDTO.cess;
            if (cess > 0) {
                const gstRate = cess.toString().padStart(2, '0');
                if (!groupedDatacess[gstRate]) {
                    groupedDatacess[gstRate] = [];
                }
                groupedDatacess[gstRate].push(entry);
            }
        });
        if (Object.keys(groupedDatacess).length > 0) {
            // Start building the HTML table
            tableHTMLCESS = '<table style="width: 3in;"><tr style="vertical-align: middle;"><td style="width: 10%; text-align: center;"></td><td style="width: 80%; text-align: center;"><b>CESS SUMMARY</b></td><td style="width: 10%; text-align: center;"></td></tr></table> <table border="1" cellspacing="0" cellpadding="0" style="width: 100%; text-align: center;"><tr><th>CESS %</th><th>Taxable</th><th>CESS AMT</th></tr>';

            // Iterate through CESS rates
            for (const gstRate in groupedDatacess) {
                // Calculate total taxable amount
                const total = groupedDatacess[gstRate].reduce((acc, entry) => acc + parseFloat(entry.SaleMasDTO.taxableamt), 0).toFixed(2);

                // Build table rows
                tableHTMLCESS += ` <tr><td style="width: 30%; text-align: center;">${gstRate}%</td><td style="width: 30%; text-align: end;">${total}&nbsp;</td><td style="width: 20%; text-align: end;">${Number((Number(total) * gstRate / 100)).toFixed(2)}&nbsp;</td></tr>`;
            }

            // Close the table
            tableHTMLCESS += '</table> <p style="font-size: 6px;">-</p>';

            // Print the HTML table
            console.log(tableHTMLCESS);
        } else {
            // Print a message indicating no CESS data found
            console.log("No CESS data found.");
        }

    }

        const tableInfo = data.length > 0 && data[0].tablename !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Table : ${data[0].tablename}</p1></span>` : '';

        const billprintlineTC1 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintlineTC1 : null; const Html_billprintlineTC1 = billprintlineTC1 !== null ? `<b> <p style="text-align: left; font-size: 12px; width:3in;">${billprintlineTC1}</p> </b>` : '';
        const billprintlineTC2 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintlineTC2 : null; const Html_billprintlineTC2 = billprintlineTC2 !== null ? `<p style="text-align: left; font-size: 10px; width:3in;">${billprintlineTC2}</p> ` : '';
        const billprintlineTC3 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintlineTC3 : null; const Html_billprintlineTC3 = billprintlineTC3 !== null ? `<p style="text-align: left; font-size: 10px; width:3in;">${billprintlineTC3}</p> ` : '';
        const billprintlineTC4 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintlineTC4 : null; const Html_billprintlineTC4 = billprintlineTC4 !== null ? `<p style="text-align: left; font-size: 10px; width:3in;">${billprintlineTC4}</p> ` : '';
        const billprintlineTC5 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintlineTC5 : null; const Html_billprintlineTC5 = billprintlineTC5 !== null ? `<p style="text-align: left; font-size: 10px; width:3in;">${billprintlineTC5}</p> ` : '';

        const billprintline1 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintline1 : null; const billprintline1Html1 = billprintline1 !== null ? `<p style="text-align: center; width:3in;">${billprintline1}</p>` : '';
        const billprintline2 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintline2 : null; const billprintline1Html2 = billprintline2 !== null ? `<p style="text-align: center; width:3in;">${billprintline2}</p>` : '';
        const billprintline3 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintline3 : null; const billprintline1Html3 = billprintline3 !== null ? `<p style="text-align: center; width:3in;">${billprintline3}</p>` : '';
        const billprintline4 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintline4 : null; const billprintline1Html4 = billprintline4 !== null ? `<p style="text-align: center; width:3in;">${billprintline4}</p>` : '';
        const billprintline5 = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].billprintline5 : null;
        const billprintline1Html5 = billprintline5 !== null ? `<p style="text-align: center; width:3in;">${billprintline5}</p>` : '';
        const Billamtinword = `<p style="text-align: center; width:3in;">In Word : ${numberToWords.toWords(Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totblamt))), 0)))}</p>`;
        const custdlvdetail = data.length > 0 ? data[0].SaleMasDTO.custmob : null;
        const custdlvdetailHtml = custdlvdetail !== null && custdlvdetail !== 0 ? `       <hr style="borderTop: 1px solid black; width: 3in;" /> <span style="display:flex; width: 3in;"><p1 style="text-align: center; width:100%;"><b><u>Customer Details</u></b></p1></span>
        <span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%; font-size: 14px;">${data.length > 0 ? data[0].SaleMasDTO.custname : ''}</p1><p1 style="text-align: end; width:50%;font-size: 14px;">${data.length > 0 ? data[0].SaleMasDTO.custmob : ''}</p1></span>
        <span style="display:flex; width: 3in;"><p1 style="text-align: start; width:100%; font-size: 14px;">${data.length > 0 ? data[0].SaleMasDTO.custadd1 : ''}</p1></span>
` : '';

        const showBillDisc = data.length > 0 ? data[0].SaleMasDTO.bldiscperc : null;
        const showBillDisc_HTML = showBillDisc !== null && showBillDisc !== 0 ? `<tr style="vertical-align: middle;">
                <td style="width: 80%; text-align: end;">DISCOUNT ON BILL ${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldiscperc))), 0))}% :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.bldiscamt))), 0)).toFixed(2)}</td></tr>
            ` : '';
        const showBillservCH = data.length > 0 ? data[0].SaleMasDTO.servicechperc : null;
        const showBillservCH_HTML = showBillservCH !== null && showBillservCH !== 0 ? `            <tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">SERVICE CHARGE ${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.servicechperc))), 0))}% :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.servicechamt))), 0)).toFixed(2)}</td></tr>
            ` : '';

        const showBilldlvCH = data.length > 0 ? data[0].SaleMasDTO.bldlvchperc : null;
        const showBilldlvCH_HTML = showBilldlvCH !== null && showBilldlvCH !== 0 ? `            <tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">DELIVERY CHARGE ${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldlvchperc))), 0))}% :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldlvchamt))), 0)).toFixed(2)}</td>    </tr>
            ` : '';

        const showBilldlvCHAmt = data.length > 0 ? data[0].SaleMasDTO.bldlvchamount : null;
        const showBilldlvCHAmt_HTML = showBilldlvCHAmt !== null && showBilldlvCHAmt !== 0 ? `            <tr style="vertical-align: middle;">
            <td style="width: 80%; text-align: end;">DELIVERY CHARGE :</td>
            <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldlvchamount))), 0)).toFixed(2)}</td></tr>
            ` : '';

            // ${Number((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))+(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.gst / 100) +(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.cess / 100)).toFixed(2)}
            //${Number(data.reduce((total, x) => total + parseFloat(Number((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))+(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.gst / 100) +(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.cess / 100)).toFixed(2)), 0)).toFixed(2)}
        const showFlatDiscount = data.length > 0 ? data[0].SaleMasDTO.flatdiscount : null;
        const showFlatDiscount_HTML = showFlatDiscount !== null && showFlatDiscount !== 0 ? `            <tr style="vertical-align: middle;">
            <td style="width: 80%; text-align: end;">FLAT DISCOUNT :</td>
            <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.flatdiscount))), 0)).toFixed(2)}</td></tr>
            ` : '';


        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;overflow-x: hidden;}div{width: 3in;}hr{width: 3in;} .advertisement-text {position: absolute;font-size: small;white-space: nowrap;transform-origin: right;color: #ffffff;left: -12mm;top:0;transform: rotate(-90deg);}</style><div style="font-family: 'Bahnschrift Condensed'; width: 3in;"><p1 style="display: block; margin: 0 auto;text-align: center;">${data.length > 0 ? data[0].bltype : ''}</p1>
        <img style="display: block; margin: 0 auto;" src="./logo${usershopid}.png" alt="" />
        <p class="advertisement-text">MYPCKART</p>
         <h1 style="text-align: center;font-Size:${fs_cname}px;">${localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : '0'}</h1>
        ${billprintline1Html1}        ${billprintline1Html2}        ${billprintline1Html3}        ${billprintline1Html4}        ${billprintline1Html5}
        <hr style="borderTop: 1px solid black; width: 3in;" />
        <b><span style="display:flex;"><p1 style="text-align: start; width:50%;">Bill No : ${data.length > 0 ? data[0].SaleMasDTO.shopvno : ''}
        </p1>${tableInfo}</p1></span><span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Bill Date : ${data[0].SaleMasDTO.bldate ? new Date(data[0].SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;">Bill Time : ${data[0].SaleMasDTO.entrytime ? new Date(`2022-01-01T${data[0].SaleMasDTO.entrytime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1></span>
        <span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Waiter : ${data[0].SaleMasDTO.wname ? data[0].SaleMasDTO.wname : "NA"}</p1><p1 style="text-align: end; width:50%;"> NOP : ${data[0].SaleMasDTO.nop ? data[0].SaleMasDTO.nop : "NA"}</p1></span>
        </b>
        
        ${custdlvdetailHtml}
        <table style="border-Top: 1px solid black; width: 3in;">
        <tr style="border-bottom: 1px solid black; width: 100%;">
            <td style="width: 10%;border-bottom: 1px solid black;">SN</td>
            <td style="width: 40%;border-bottom: 1px solid black;">Item Name</td>
            <td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td>
            <td style="width: 20%;border-bottom: 1px solid black; text-align: end;">Rate</td>
            <td style="width: 20%;border-bottom: 1px solid black; text-align: end;">Amount</td>
        </tr>
        ${data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td>
        <td style="width: 40%; max-width: 15ch; word-wrap: break-word;">
    ${x.ItemMaster.itname} <br> ${x.SaleMasDTO.discperc !== 0 ? `Discount : ${x.SaleMasDTO.discperc}%` : ''}
</td>

          <td style="width: 10%; text-align: center;">${x.SaleMasDTO.qty}</td>
          <td style="width: 20%; text-align: end;">${x.SaleMasDTO.rate.toFixed(2)}</td>
          <td style="width: 20%; text-align: end;">${Number(x.SaleMasDTO.rate * x.SaleMasDTO.qty).toFixed(2)}</td>
          </tr>`).join('')}

          
          
          <tr style="border-top: 1px solid black;vertical-align: middle;">
            <td style="width: 10%;border-top: 1px solid black; border-bottom: 1px solid black; "></td>
            <td style="width: 30%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: start; vertical-align: middle;"><b>Total :</b></td>
            <td style="width: 10%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: end;"><b>${Number(data.reduce((total, resp) => total + parseFloat(resp.SaleMasDTO.qty), 0)).toFixed(2)}</b></td>
            <td style="width: 20%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: end;"></td>
            <td style="width: 10%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: end;"><b>${Number(data.reduce((total, resp) => total + parseFloat(resp.SaleMasDTO.qty*resp.SaleMasDTO.rate), 0)).toFixed(2)}</b></td>
          </tr>
          </table>

         
          
          <table style="width: 3in;">
                ${showBillDisc_HTML}
            <tr style="vertical-align: middle;">
                <td style="width: 80%; text-align: end;">TOTAL TAXABLE AMOUNT :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.taxableamt))), 0)).toFixed(2)}</td>
            </tr>
            
            <tr style="">
                <td style="width: 80%; text-align: end;">TOTAL CGST :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totgst / 2))), 0)).toFixed(2)}</td>
            </tr>
            <tr style="">
                <td style="width: 80%; text-align: end;">TOTAL SGST :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totgst / 2))), 0)).toFixed(2)}</td>
            </tr>
            <tr style="">
                <td style="width: 80%; text-align: end;">TOTAL CESS :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.cessamt))), 0)).toFixed(2)}</td>
            </tr>
            ${showBillservCH_HTML}
            ${showBilldlvCH_HTML}
            ${showBilldlvCHAmt_HTML}
            ${showFlatDiscount_HTML}

            <tr style="">
                <td style="width: 80%; text-align: end;">ROUND OFF :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.roundoff))), 0)).toFixed(2)}</td>
            </tr>
            <tr style="">
                <td style="width: 80%; text-align: end;font-size: 20px;" class="bill-amount-value"><b>BILL AMOUNT :</b></td>
                <td style="width: 20%; text-align: end;font-size: 20px;"><b>${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totblamt))), 0)).toFixed(2)}</b></td>
            </tr>

            </table>
            <hr style="border: 0.1px solid black; width: 3in;transform: scaleY(0.5);">
            ${Billamtinword}
            ${tableHTML}
          
            ${tableHTMLCESS}
            <hr style="border: 0.1px solid black; width: 3in;transform: scaleY(0.5);">
            ${Html_billprintlineTC1}
            ${Html_billprintlineTC2}
            ${Html_billprintlineTC3}
            ${Html_billprintlineTC4}
            ${Html_billprintlineTC5}

            <hr style="border: 0.1px solid black; width: 3in;transform: scaleY(0.5);">
            <b>
            <span style="display:flex; width: 3in;"><p1 style="text-align: center; width:100%; font-size: 14px;">*** THANK YOU PLEASE VISIT AGAIN ***</p1></span>
            </b>
            <hr style="border: 0.1px solid black; width: 3in;transform: scaleY(0.5);">

        </div>`;
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "",
        statusupdate: ""
    })




    const [AccCode, setAccCode] = useState([])
    const { deleteid, deletemsg, statusupdate } = messagedata;
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [blno, setblno] = useState(0)
    const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
    const handleOpenModal = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint, statusupdate: 1 }); setIsModalOpen(true); };
    const handleOpenModaledit = (e) => { setblno(e); setIsModalOpenEdit(true); };
    const handleOpenModalCancel = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint, statusupdate: 2 }); setIsModalOpenCancel(true); };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleCloseModalCancel = () => { setIsModalOpenCancel(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const handleNoClickCancel = () => { console.log('User clicked No'); handleCloseModalCancel(); };
    const handleCloseModalEdit = () => { setIsModalOpenEdit(false); window.location.assign("/SaleBill") };
    const handleNoClickEdit = () => { console.log('User clicked No'); handleCloseModalEdit(); };
    var billgst;

    var billgst;
    const [logroom, setLogroom] = useState();
    const [GST_P_1, setGST_P_1] = useState(0);
    const [GST_P_2, setGST_P_2] = useState(0);
    const [GST_P_3, setGST_P_3] = useState(0);
    const [GST_P_4, setGST_P_4] = useState(0);

    let bl_Tot_1 = 0;
    let bl_RoomRent = 0;
    let bl_Disc = 0;
    let bl_total = 0;
    let bl_gstTotal = 0;
    let bl_blamt = 0;

    const [roomdetail, setroomdetail] = useState([]);
    const today = new Date();
    const [refreshData, setRefreshData] = useState(false)
    const api = "/bill";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const [roomrentcalculation, setroomrentcalculation] = useState([]);
    const [newItemdata, setItemdata] = useState({ chdate: '', rtype: 0, roomtypeview: '', roomnoview: '', rcode: 0, rent: 0, exbed: 0, exbedch: 0, chid: 0 });
    const Closeform = () => {
        window.location.assign("/SaleBill")
    }
    const [viewbldate, setBldate] = useState({
        blfromdate: today.toISOString().split('T')[0],
        bltodate: today.toISOString().split('T')[0]
    })
    const { blfromdate, bltodate } = viewbldate;
    const bldatechange = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setBldate({ ...viewbldate, [name]: value })
        setRefreshData(true)
    }
    const [logdetail, setlogdetail] = useState({
        onform: "SALE",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const handleCheckout = async (e, id, rnamedata) => {

        await updateStatus(id); // Pass both the event object and the ID to updateStatus
        handleOpenModaledit(rnamedata)
        // billtotal(); // Call billtotal after updating status


    };
    const getrent = (e) => {
        axios.get(`${hostlink}/room/${e}`)
            .then((Response) => {

                document.getElementById("extrabadchrge").value = Response.data.exrent
                document.getElementById("roomrent").value = Response.data.rent
                var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent }
                setSavedata(ld);
            })
            .catch((Response) => { "data Not Found" })
    }
    const getroombyid = (e) => {

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}/Restbillview/0/${blfromdate}/${bltodate}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        } else {
            axios.get(`${hostlink}/${usershopid}/Restbillview/0/${blfromdate}/${bltodate}/${e}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        }
    }

    const saveBill = async (e) => {
        const rnameString = roomdetail.map(item => item.roomnoview).join(',');
        const rcodeString = roomdetail.map(item => item.rcode).join(',');

        var ld = { ...billdata, roomdescription: rnameString, roomdescriptioncode: rcodeString, narration: usershopid + document.getElementById("checkoutdate").value.trim() + document.getElementById("inputname").value.trim() }
        await axios.put(`${hostlink}/bill`, ld)
            .then((respbill) => {
                var billno;
                billno = (respbill.data)
                var ld = {
                    ...logdetail,
                    newdesc: `Bill No : ${respbill.data}, Mob No : ${guestmob}, Name : ${guestname}`,
                    operation: "Bill", olddesc: "",
                }; setlogdetail(ld); saveLog(ld)
                // axios.post(`${hostlink}/checkin/blno/${respbill.data}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/bldate/${checkoutdate}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/statusbill/${checkinid}`)
                // const rcodeString = roomdetail.map(item => item.rcode).join(',');
                // axios.post(`${hostlink}/room/updateroombulk/0/${rcodeString}`)
                toast("Save Sucessfull")
                setTimeout(() => {
                    window.location.assign("/SaleBill")
                }, 1000);
            })
            .catch(() => {
                console.log("Data Not Save")
            })
    }


    const [savedata, setSavedata] = useState({
        id: "",
        indate: "",
        mobno: "",
        gname: "",
        add1: "",
        add2: "",
        documentno: "",
        documentpath: "",
        email: "",
        gstno: "",
        cname: "",
        rtype: "",
        rcode: "",
        rent: "",
        exbed: "",
        exbedch: "",
        mg: "",
        fg: "",
        cg: "",
        stday: "",
        discount: "",
        advance: "",
        paidby: "",
        remarks: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        acccode: 0
    })

    const { mobno, gname, add1, add2, documentno, documentpath, indate, email, gstno, cname, rcode, rtype, rent, exbed, exbedch, mg, fg, cg, stday, discount, advance, paidby, remarks } = savedata;
    const [billdata, setBilldata] = useState({
        id: "",
        checkindate: "",
        checkoutdate: today.toISOString().split('T')[0],
        bldate: today.toISOString().split('T')[0],
        roomcode: "",
        guestroomname: "",
        roomrent: 0,
        extrabed: 0,
        extrabedch: 0,
        discountperc: 0,
        adddiscountamt: 0,
        additionalcharge: 0,
        advanceamount: 0,
        blpaidby: "",
        guestmob: "",
        guestname: "",
        guestadd1: "",
        guestadd2: "",
        guestdocno: "",
        guestemail: "",
        guestgstno: "",
        guestcompanyname: "",
        blremarks: "",
        bltotaldays: "",
        bltotal: "",
        blroomrent: "",
        discountpercamt: "",
        blgstperc: "",
        guestroomname: "",
        blgstamt: "",
        blamt: "",
        netpayamt: "",
        checkinid: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomdescription: "",
        roomdescriptioncode: "",
        acccode: 0,
        roomserviceamt: 0
    })
    const { checkinid, checkindate, acccode, checkoutdate, roomcode, roomrent, extrabed, extrabedch, discountperc, roomserviceamt, adddiscountamt, additionalcharge, advanceamount, blpaidby, guestmob, guestname, guestadd1, guestadd2, guestdocno, guestemail, guestgstno, guestcompanyname, blremarks, bltotaldays, bltotal, blroomrent, discountpercamt, blgstperc, blgstamt, blamt, netpayamt } = billdata;


    const billtotal = (e) => {
        var value = bl_Tot_1
        var ld = {
            ...billdata, bltotaldays: bl_Tot_1,
            bltotal: (value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100),
            bldate: document.getElementById("checkoutdate").value,
            blroomrent: bl_RoomRent,
            discountpercamt: bl_Disc,
            blgstamt: bl_gstTotal,
            bltotal: (bl_RoomRent - bl_Disc),
            blamt: ((((bl_RoomRent - bl_Disc) + (bl_gstTotal) + roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)),
            netpayamt: (((((bl_RoomRent - bl_Disc) + (bl_gstTotal) + roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)) - Number(advanceamount))
        }
        setBilldata(ld);
        setIsModalOpenEdit(false);
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const onChangevaluebill = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setBilldata({ ...billdata, [name]: sanitizedValue });
    }

    const updateStatus = async (e) => {
        let x = document.getElementById("frm_checkoutdetail")
        x.style.display = "block"
        await axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setBilldata(Response.data)
                console.log(Response.data)

                var ld = {
                    ...logdetail,
                    olddesc: `Mob No : ${Response.data.mobno}, Name : ${Response.data.gname}, Rent : ${Response.data.rent}, Ex Bed : ${Response.data.exbed}, Bed Ch : ${Response.data.exbedch} , Advance : ${Response.data.advance}`,
                    operation: "Check-Out"
                }
                setlogdetail(ld);

                axios.get(`${hostlink}/getchehinroomDetail/${Response.data.checkinid}`)
                    .then((resp) => {
                        setroomdetail(resp.data)

                    })
            })

            .catch((res) => { console.log("Data Not find") })
    }
    const deleteData = async (e) => {
        await axios.post(`${hostlink}/Restbillview/${usershopid}/${deletemsg}/1`)
            .then((responce) => {
                toast.success("Delete Sucessfull")
                setInterval(() => {
                    handleCloseModal();
                    window.location.assign("/RestBillView")
                    setRefreshData(true)
                }, 1000);
            })
            .catch((resp) => { console.log("Data Not Fatch..") })
    }
    const cancelBill = async (e) => {
        await axios.post(`${hostlink}/Restbillview/${usershopid}/${deletemsg}/2`)
            .then((Response) => {
                handleCloseModalCancel();
                toast.success("Cancel Sucessfull")
                setRefreshData(true)
                console.log(Response.data)
            })
            .catch(() => {

            })
        //  setRefreshData(true)
    }
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"

        setRefreshData(false)
        axios.get(`${hostlink}/${usershopid}/Restbillview/0/${blfromdate}/${bltodate}`)
            .then((Response) => {
                console.log("Data Fatched")

                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
            })
            .catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/${usershopid}/accmas`)
            .then((Response) => {
                setAccCode(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }, [refreshData])
    return (
        <div className='p-1 ' >

            {/* /// Check In  */}
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode' > Check-OUT [ New ]</span></h5>

                <div class="col-md-12 text-end">


                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-database"></i> Bill Details</h5>
                    </div>
                    <div className='col-md-3 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />

                    </div>

                    <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                        </span>
                        <span> <input type="date" id="blfromdate" name="blfromdate" onChange={(e) => { bldatechange(e); }} value={blfromdate} className="form-control" /></span>


                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="bltodate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                        </span>
                        <span>
                            <input type="date" id="bltodate" name="bltodate" onChange={(e) => { bldatechange(e); }} value={bltodate} className="form-control" />
                        </span>
                    </div>


                    <div style={{ height: "80vh", verticalAlign: 'middle', height: '100%' }} className='col-12 '>

                        <table className="table table-hover table-striped" >
                            <thead>
                                <tr style={{ height: '100%' }}>

                                    <th scope="col" className="f-size-on-mobile" >BlNo</th>
                                    <th scope="col" className="f-size-on-mobile">Bill <span className="hide-on-mobile" >Date</span>  <span className="detail-on-mobile"> Details</span> </th>
                                    <th scope="col" className="hide-on-mobile">Time</th>
                                    <th scope="col" className="hide-on-mobile">Type</th>
                                    <th scope="col" className="hide-on-mobile">Table</th>
                                    <th scope="col" className="hide-on-mobile">Bill Amount</th>
                                    <th scope="col" className="hide-on-mobile">Guest Mob</th>
                                    <th scope="col" className="hide-on-mobile">Guest Name</th>
                                    <th scope="col" className="f-size-on-mobile align-self-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (
                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                        <td className="f-size-on-mobile align-self-center text-center" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.shopvno}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <span className="detail-on-mobile"><b> Bill Date : {res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b> </span>  <span className='hide-on-mobile'>{res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</span> <br />
                                            <div className="detail-on-mobile" style={{ fontSize: "12px", color: "blue" }}>
                                                Ord Type : {res.bltype} <br />
                                                Guest : {res.SaleMasDTO.custname} <br /> Mob : {res.SaleMasDTO.custmob} <br /> Table : {res.tablename},
                                                Gst Amount : {res.SaleMasDTO.totgst}  <br />
                                                <span style={{ color: "red" }}> <b>Bill Amount : {res.SaleMasDTO.totblamt} </b></span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.entrytime ? new Date(`2022-01-01T${res.SaleMasDTO.entrytime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.bltype}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.tablename}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.totblamt}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.custmob}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.custname}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                        <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res.SaleMasDTO.shopvno) }}><i class="fa-solid fa-circle-info"></i><span className="hide-on-mobile"></span></Link> &nbsp;

                                            <Link onClick={(e) => { handleOpenModal(res.SaleMasDTO.id, res.SaleMasDTO.shopvno, 1) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"></span></Link>&nbsp; <div className='show-on-mobile'><p></p></div>
                                            <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res.SaleMasDTO.shopvno) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"></span></Link> &nbsp;
                                            <Link onClick={(e) => { handleOpenModalCancel(res.SaleMasDTO.id, res.SaleMasDTO.shopvno, 2) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-ban"></i>  <span className="hide-on-mobile"></span></Link>&nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpenEdit} onRequestClose={handleCloseModalEdit} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Edit Bill ? <br /> Room No : {blno} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { billtotal(1); }}>Yes</button> <button className="no-button" onClick={handleNoClickEdit}>No</button></div></div>
            </Modal>


            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this Bill No : {deletemsg}?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>

            <Modal isOpen={isModalOpenCancel} onRequestClose={handleCloseModalCancel} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div clas><p className='text-bg-danger'>Are you sure you want to Cancel this Bill No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cancelBill(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClickCancel}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default RestBillView
