import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import hostlink from '../Hostlink/hostlink'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
const MergeTable = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [runningtable, setrunningtable] = useState([])
    const [availabletable, setavailabletable] = useState([])
    const roomCodeRef = useRef(null);
    const [trftabledata, settrftabledata] = useState({ fromtable: 0, totable: 0, nop: 0, wcode: 0, wname: "",totablename:"" });
    const { fromtable, totable, nop, wcode, wname,totablename } = trftabledata;
    const onChangefromtable = (e, f) => {
        const existingItem = runningtable.find(item => item.tname === f);
        if (existingItem) {
            const updatedItDetails = runningtable.map(tabledata => {
                if (tabledata.tname === f) {
                    settrftabledata({ ...trftabledata, fromtable: tabledata.id, nop: tabledata.nop, wcode: tabledata.wcode, wname: tabledata.wname })
                    console.log(tabledata.id)
                }
            });
        }
    }
    const onChangetotable = (e, f) => {
        const existingItem = availabletable.find(item => item.tname === f);
        if (existingItem) {
            const updatedItDetails = availabletable.map(tabledata => {
                if (tabledata.tname === f) {
                    settrftabledata({ ...trftabledata, totable: tabledata.id, totablename:tabledata.tname })
                    console.log(tabledata.id)
                }
            });
        }
    }

    const conformTRF = () => {
        if (fromtable == 0) {
            toast.error("Please Select From Table..")
            document.getElementById("roomcode").focus();
            return false;
        }
        if (totable == 0) {
            toast.error("Please Select To Table..")
            document.getElementById("roomcode2").focus();
            return false;
        }
        if (totable == fromtable) {
            toast.error("Please Select Valid Table Both are Same..")
            document.getElementById("roomcode").focus();
            return false;
        }
       
        axios.post(`${hostlink}/tableMrg/${usershopid}/${fromtable}/${totable}/${totablename}/${nop}/${wcode}/${wname}`)
            .then((resp) => {  
                toast("Save Sucessfull")
                setTimeout(() => {
                window.location.assign("/MergeTable")
            }, 1000); }).catch((resp) => { console.log("Something Else") })
    }
    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/table/1`)
            .then((resp) => { setavailabletable(resp.data);setrunningtable(resp.data); }).catch((resp) => { console.log("Not Fatch") })
        // axios.get(`${hostlink}/${usershopid}/table/1`)
        //     .then((resp) => { setrunningtable(resp.data); }).catch((resp) => { console.log("Not Fatch") })
    }, [])
    return (
        <div className='container'>
            <h3 className="text-center my-4"> <i class="fa-solid fa-code-merge"></i>  Merge Table</h3>
            <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'white' }}>

                <div className="col-md-2 text-center align-self-center"><h4 for="inputState" class="form-label"> From Table</h4></div>
                <div class="col-md-4 align-self-center">
                    <select id="roomcode" ref={roomCodeRef} onChange={(e) => { onChangefromtable(e.target.value, e.target.selectedOptions[0].text) }} class="form-select">
                        <option selected key={0} value={0}>Choose...</option>
                        {runningtable.map((x) => {
                            return (
                                <option key={x.tname} value={x.tname} title={x.id}> {x.tname} </option>
                            )
                        })}
                    </select>
                </div>

                <div className="col-md-2 text-center align-self-center"><h4 for="inputState" class="form-label"> To Table</h4></div>
                <div class="col-md-4 align-self-center">
                    <select id="roomcode2" ref={roomCodeRef} onChange={(e) => { onChangetotable(e.target.value, e.target.selectedOptions[0].text) }} class="form-select">
                        <option selected key={0} value={0}>Choose...</option>
                        {availabletable.map((x) => {
                            return (
                                <option key={x.tname} value={x.tname} title={x.id}> {x.tname} </option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div className="text-center my-4">
                <button onClick={() => { conformTRF() }} className="btn btn-success" ><i class="fa-solid fa-code-merge"></i> Confirm Merge</button>
            </div>

            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default MergeTable