import axios from 'axios';
import React, { useEffect, useState } from 'react';
import hostlink from '../Hostlink/hostlink';

const PrintKot = () => {
  const [PrintData, setPrintData] = useState([]);
  const [imageError, setImageError] = useState(false);
  const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';

  const printTest = () => {
    const printWindow = window.open('', '_blank');
    const printContent = `
    <style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 3in;}hr{width: 3in;}</style>
      <div style="font-family: 'Bahnschrift Condensed'; width: 3in;">
        <p1 style="display: block; margin: 0 auto;text-align: center;">${PrintData.length > 0 ? PrintData[0].KottypeName : ''}</p1>${!imageError ? `<img style="display: block; margin: 0 auto;" src="./logo${usershopid}.png" alt="" />` : ''}<h1 style="text-align: center;">${localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : '0'}</h1><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;">
        <p1 style="text-align: start; width:50%;">Kot No : ${PrintData.length > 0 ? PrintData[0].kotMasDTO.shopvno : ''}</p1><p1 style="text-align: end; width:50%;">Table : ${PrintData.length > 0 ? PrintData[0].tablename : ''}</p1></span><span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Date : ${PrintData[0].kotMasDTO.kotdate ? new Date(PrintData[0].kotMasDTO.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;"> Time : ${PrintData[0].kotMasDTO.kottime ? new Date(`2022-01-01T${PrintData[0].kotMasDTO.kottime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1></span><hr style="borderTop: 1px solid black; width: 3in;" /><table style="borderTop: 1px solid black; width: 3in;">
        <tr><td style="width: 10%;">SN</td><td style="width: 80%;">Item Name</td><td style="width: 10%; text-align: end;">Qty</td></tr>
        ${PrintData.map((x, index) => `
          <tr style="width: 100%">
            <td style="width: 10%">${index + 1}</td>
            <td style="width: 80%">${x.kotMasDTO.itname}</td>
            <td style="width: 10%; text-align: end;">${x.kotMasDTO.qty.toFixed(2)}</td>
          </tr>
        `).join('')}
      </table>
      <hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: end; width:100%;">Total Qty : ${Number(PrintData.reduce((total, resp) => total + parseFloat(resp.kotMasDTO.qty), 0)).toFixed(2)}</p1></span><hr style="borderTop: 1px solid black; width: 3in;" />      
      </div>
    `;
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.print();
  }

  useEffect(() => {
    axios.get(`${hostlink}/KotviewOne/${usershopid}/4`)
      .then((response) => {
        setPrintData(response.data);
        console.log("Data loaded");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [usershopid]);

  return (
    <div style={{ width: "3in", fontFamily: "Bahnschrift Condensed" }}>
      <img style={{ width: "76mm" }} src={`./logo${usershopid}.png`} alt="" />
      <h1 style={{ textAlign: "center" }}>{localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : '0'}</h1>
      <div>
        <span>Kot No : {PrintData.length > 0 ? PrintData[0].kotMasDTO.shopvno : ''}</span>
        <span>{PrintData.length > 0 ? PrintData[0].KottypeName : ''}</span>
      </div>
      <table>
        <tr><td>SN</td><td>Item Name</td><td>Qty</td></tr>
        {PrintData.map((x, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{x.kotMasDTO.itname}</td>
            <td>{x.kotMasDTO.qty}</td>
          </tr>
        ))}
      </table>
      <button onClick={printTest}>Print</button>
    </div>
  );
}

export default PrintKot;
