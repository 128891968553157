import axios from "axios";
import hostlink from "./hostlink";

const user_code = 1;
const user_computer = "Admin";
let GST_1 ; //5
let GST_2 = 200; //12
let GST_3 = 300; //18
let GST_4 = 400; //28

const loadData = async () => {
    try {
        const response = await axios.get(`${hostlink}/setting`);
        GST_1 = 200000;
    } catch (error) {
        console.error("Error loading data:", error);
    }
};

loadData();

export default {
    user_code,
    user_computer,
    GST_1,
    GST_2,
    GST_3,
    GST_4,
};
