import React from 'react'

const MessageBox = () => {
  return (
    <div>
        <div className='container' >
          <input className='form-control' type="text" />
        </div>

    </div>
  )
}

export default MessageBox