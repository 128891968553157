import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Printdata } from './Printdata'; 

const PrinterSelector = ({ hostlink, usershopid, print_hidekotno }) => {
    const [printers, setPrinters] = useState([]);
    const [defaultPrinter, setDefaultPrinter] = useState('');
    const [selectedPrinter, setSelectedPrinter] = useState('');

    useEffect(() => {
        fetchPrinters();
        fetchDefaultPrinter();
    }, []);

    const fetchPrinters = async () => {
        const response = await axios.get(`${hostlink}/printers`);
        setPrinters(response.data);
    };

    const fetchDefaultPrinter = async () => {
        const response = await axios.get(`${hostlink}/printers/default`);
        setDefaultPrinter(response.data);
    };

    const handlePrint = async (e) => {
        await Printdata(e, selectedPrinter || defaultPrinter, hostlink, usershopid, print_hidekotno);
    };

    return (
        <div>
            <h1>Select Printer</h1>
            <select onChange={(e) => setSelectedPrinter(e.target.value)} value={selectedPrinter}>
                <option value="">Default Printer: {defaultPrinter}</option>
                {printers.map((printer, index) => (
                    <option key={index} value={printer}>{printer}</option>
                ))}
            </select>
            <button onClick={() => handlePrint('someId')}>Print</button>
        </div>
    );
};

export default PrinterSelector;
