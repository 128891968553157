import axios from 'axios';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import hostlink from '../Hostlink/hostlink';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';

const BillPaymentRest = () => {
  const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
  const [TableListUnbilled, setTableListUnbilled] = useState([]);
  const [cmbAccdata, setcmbAccdata] = useState([]);
  const [cmbAccdataBank, setcmbAccdataBank] = useState([]);
  const [selected, setSelected] = useState(0);
  const [Payselected, setPayselected] = useState(0);

  const handleChange = (index) => {
    setSelected(index);
    setPayselected(index);
    setMessagedata({ ...messagedata, payacccode: index })
  };
  const [messagedata, setMessagedata] = useState({
    paybldate: "",
    payblno: "",
    paybltype: "",
    payblAmt: 0,
    payacccode: "",
    pay1acc: 0,
    pay2acc: 0,
    pay1amt: 0,
    pay2amt: 0,
  })
  const { paybldate, payacccode, payblno, paybltype, payblAmt, pay1acc, pay2acc, pay1amt, pay2amt } = messagedata;



  const trfbill = async (e) => {

    const queryParams = new URLSearchParams({
      paybldate,
      paybltype,
      payblno,
      payblAmt
    }).toString();
    window.location.assign(`/EntryTrfBill?${queryParams}`);
  };
  const paybill = async (e) => {

    if (Number(pay1amt) > 0) {
      if (Number(pay1acc) === 0) {
        document.getElementById("pay1acc").focus();
        toast.error("Please select a valid Paymode...");
        return false;
      }
    }

    if (Number(pay2amt) > 0) {
      if (Number(pay2acc) === 0) {
        document.getElementById("pay2acc").focus();
        toast.error("Please select a valid Paymode...");
        return false;
      }
    }

    if (Number(pay1amt) + Number(pay2amt) !== Number(payblAmt)) {
      toast.error("Please Enter a Valid Amount...");
      return false;
    }

    if (Number(pay1acc) === Number(pay2acc)) {
      toast.error("Please select Valid Paymode...");
      return false;
    }
    
    await axios.post(`${hostlink}/RestbillPaymentMultiPay/${usershopid}/${payblno}/${pay1acc}/${pay1amt}/${pay2acc}/${pay2amt}`).then((resp) => { console.log("PAID"); toast.success("Paid Sucessfull") }).catch((resp) => { console.log("Something else") });
    setInterval(() => {
      window.location.assign("/BillPaymentRest")
    }, 1000);
  };

  const onChangeacc1 = (e, f) => {
    const existingItem = cmbAccdata.find(data => data.accname === f);
    if (existingItem) {
      console.log("find")
      const updatedItDetails = cmbAccdata.map(data => {
        if (data.accname === f) {
          setMessagedata({ ...messagedata, pay1acc: data.id })
        }
      });
    }
    else {
      setMessagedata({ ...messagedata, pay1acc: 0 })
    }

  }
  const onChangeacc2 = (e, f) => {
    const existingItem = cmbAccdataBank.find(data => data.accname === f);
    if (existingItem) {
      console.log("find")
      const updatedItDetails = cmbAccdataBank.map(data => {
        if (data.accname === f) {
          setMessagedata({ ...messagedata, pay2acc: data.id })
        }
      });
    }
    else {
      setMessagedata({ ...messagedata, pay2acc: 0 })
    }
  }

  const onChangevalue = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
    setMessagedata({ ...messagedata, [name]: sanitizedValue });
  }
  const [isModalOpen_Payment, setIsModalOpen_Payment] = useState(false);
  const handleCloseModal_Payment = () => { setIsModalOpen_Payment(false); };
  const handleNoClick_Payment = () => { console.log('User clicked No'); handleCloseModal_Payment(); };
  useEffect(() => {
    console.log(`${hostlink}/RestbillviewPayment/${usershopid}`)
    axios.get(`${hostlink}/RestbillviewPayment/${usershopid}`).then((resp) => { setTableListUnbilled(resp.data) }).catch((resp) => { console.log("Not Fatch") });
    axios.get(`${hostlink}/${usershopid}/accmas/0`).then((Response) => { if (Response.data.length > 0) { setSelected(Response.data.id); } setMessagedata({ ...messagedata, pay1acc: Response.data[0].id }); setcmbAccdata(Response.data); }).catch((Response) => { "data Not Found" })
    axios.get(`${hostlink}/${usershopid}/accmas/1`).then((Response) => { setcmbAccdataBank(Response.data); }).catch((Response) => { "data Not Found" })
  }, [])

  return (
    <div style={{ "background": "linear-gradient(to bottom, #87CEEB, #ffffff)" }}>

      <div className='container' style={{ fontFamily: "Bahnschrift Condensed" }}>
        <div className="row" >
          <h4 className='text-center text-bg-dark rounded'><i class="fa-solid fa-indian-rupee-sign"></i> Payment</h4>
          {TableListUnbilled.map((x) => { return (<div className='col-md-3 p-1'><button onClick={() => { setMessagedata({ ...messagedata, paybldate: x.SaleMasDTO.bldate, payblno: x.SaleMasDTO.shopvno, paybltype: x.bltype, pay2amt: 0, pay1amt: x.SaleMasDTO.totblamt, payblAmt: x.SaleMasDTO.totblamt }); setIsModalOpen_Payment(true) }} className={`btn shadow rounded ${x.bltype === 'Delivery' ? 'btn-light' : 'btn-light'}`} value={x.id} style={{ width: "100%", border: "1px solid pink" }}>Bill Date : {x.SaleMasDTO.bldate ? new Date(x.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"} <br /> <b>Table No : {x.SaleMasDTO.tablename || "NA"}</b><br /> <b>Bill No : {x.SaleMasDTO.shopvno}</b>, Type : {x.bltype} <br /> <span style={{ color: "red" }}><b>Bill Amt : <i class="fa-solid fa-indian-rupee-sign"></i> {x.SaleMasDTO.totblamt}</b></span></button></div>) })}</div>
      </div>
      <div className="row">
        <div className="col-11 text-end">
          {/* <button style={{ width: "20%" }} onClick={() => { setIsModalOpen_Payment(false) }} className='btn btn-dark'><i class="fa-solid fa-right-from-bracket"></i> Cancel</button> */}
        </div>
      </div>



      <Modal isOpen={isModalOpen_Payment} onRequestClose={handleCloseModal_Payment} contentLabel="Delete Confirmation" shouldCloseOnOverlayClick={false} className="modal-content" overlayClassName="modal-overlay">
        <div style={{ fontFamily: "Bahnschrift Condensed" }}>
          <h4 className='text-bg-dark rounded'><i class="fa-solid fa-indian-rupee-sign"></i> Payment</h4>
          <table style={{ width: "100%" }}>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Bill Date :       </b></td><td className='text-start'> &nbsp;<b >{paybldate ? new Date(paybldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b></td></tr>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Type :       </b></td><td className='text-start'> &nbsp;<b >{paybltype}</b></td></tr>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Bill No :    </b></td><td className='text-start'> &nbsp;<b>{payblno}</b></td></tr>
            <tr style={{ width: "50%", color: "red" }}><td className='text-end' style={{ width: "50%", color: "red" }}><b>Bill Amount :</b></td><td className='text-start'> &nbsp;<b>{payblAmt}</b></td></tr>
          </table>
          <hr />
          <h3><u>Pay Mode</u></h3>
          <table style={{ width: "100%" }}>
            <tr><td className='text-end' style={{ width: "50%" }}><b>
              <div class="col-2 align-self-center">
                <select id="pay1acc" onChange={(e) => { onChangeacc1(e.target.value, e.target.selectedOptions[0].text) }} class="form-select" style={{ width: "170px" }}>
                  {cmbAccdata.map((x) => { return (<option key={x.accname} value={x.accname} title={x.id}> {x.accname} </option>) })}
                </select>
              </div>
            </b></td><td className='text-start'> <input type="text" value={pay1amt} onChange={(e) => { onChangevalue(e) }} name='pay1amt' class="form-control" id="pay1amt" /></td></tr>
            <br />
            <tr><td className='text-end' style={{ width: "50%" }}><b>
              <div class="col-2 align-self-center">
                <select id="pay2acc" onChange={(e) => { onChangeacc2(e.target.value, e.target.selectedOptions[0].text) }} class="form-select" style={{ width: "170px" }}>
                  <option selected key={0} value={0}>Choose...</option>
                  {cmbAccdataBank.map((x) => { return (<option key={x.accname} value={x.accname} title={x.id}> {x.accname} </option>) })}
                </select>
              </div>
            </b></td><td className='text-start'> <input type="text" value={pay2amt} onChange={(e) => { onChangevalue(e) }} name='pay2amt' class="form-control" id="pay2amt" /></td></tr>
          </table>

          {/* <div className='col-12 text-center p-1' style={{ display: "flex", fontFamily: "Bahnschrift Condensed", overflowX: "scroll", WebkitOverflowScrolling: "touch", scrollbarWidth: "none", msOverflowStyle: "none", }}> <style>{`.scroll-container::-webkit-scrollbar {display: none;}`}</style>
            {cmbAccdata.map((x, index) => (<div key={x.id} className="col-md-3 p-1 bor " style={{ border: "1px solid black", backgroundColor: selected === x.id ? 'lightblue' : 'white', flex: "0 0 auto" }}>
              <input type="radio" id={`id-${x.id}`} name="paymentnew" value={x.id} checked={selected === x.id} onChange={() => handleChange(x.id)} style={{ width: "10%" }} /> &nbsp;
              <label htmlFor={`id-${x.id}`} onClick={() => handleChange(x.id)} style={{ width: "80%" }} className='text-start'> {x.accname} </label><br />
            </div>))}
          </div> */}
          <br />
          <button onClick={() => { paybill(); }} className='btn btn-danger'>Proceed to Pay</button> &nbsp;
          <button onClick={() => { trfbill(); }} className='btn btn-info'>Proceed to Room</button> &nbsp;
          <button onClick={() => { setIsModalOpen_Payment(false) }} className='btn btn-dark'>Cancel</button>
        </div>

      </Modal>
      <ToastContainer autoClose={1000}></ToastContainer>
    </div>
  )
}

export default BillPaymentRest