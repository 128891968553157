import axios from 'axios';

export const Printdata = async (e, selectedPrinter, hostlink, usershopid, print_hidekotno) => {
    try {
        const response = await axios.get(`${hostlink}/KotviewOne/${usershopid}/${e}`);

        if (response && response.data && response.data.length > 0) {
            const data = response.data;

            // Prepare the print request payload
            const printRequest = {
                data: data,
                printHideKotNo: print_hidekotno === 0 ? false : true // Adjust this based on your actual condition
            };

            // Send the print request to the Spring Boot endpoint
            const printResponse = await axios.post(`${hostlink}/print`, printRequest, {
                params: { printerName: selectedPrinter }
            });

            if (printResponse && printResponse.data) {
                console.log(printResponse.data);
            } else {
                console.error("Failed to send print request.");
            }
        } else {
            console.error("Empty or invalid response received.");
        }
    } catch (error) {
        console.error("Error fetching or printing data:", error);
    }
};
