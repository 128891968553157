import React, { useState } from 'react';
import './Shop_Create.css'; // Import CSS file for styling
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import Loading from './Loading'
import { Link } from 'react-router-dom';
const RecoveryyPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Mailotp, setMailotp] = useState('');

    const handleOpenModal = (e, flname) => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [savedata, setSavedata] = useState({

        email: "",
        password: "",
        pass1:"",
        pass2:""
    });
    const { email, password, pass1, pass2 } = savedata;

    const generateOTP = () => {
        return Math.floor(100000 + Math.random() * 900000).toString();
    };
    

    const updatepassword = async (e) => {
        setIsLoading(true);
        console.log(Mailotp)

        if (pass1 == pass2) {
            const nameQuery = `update shopmas set password = '${pass1}' where email = '${email}'`;
            const nameResponse = await axios.post(`${hostlink}/api/executeUpdate?sqlQuery=${encodeURIComponent(nameQuery)}`);
            
            if (nameResponse.data == 'Rows affected: 1') {
                toast.success("Password change success..")
                setTimeout(() => {
                    window.location.assign("/")
                }, 2000);
                return false;
            }
            toast.error("Something went wrong..");
            return false
        }
        else {
            setIsLoading(false);
            toast.error("Password doesn't match..")
            document.getElementById("pass1").focus();
            return false

        }
    }


    const Verifyotp = async (e) => {
        setIsLoading(true);
        console.log(Mailotp)

        if (password == Mailotp) {

            let x = document.getElementById("newpassword")
            x.style.display = "unset"

            
            let y = document.getElementById("beforeveryfyemail")
            y.style.display = "none"


            toast.success("Otp verified success...")
            setIsLoading(false);
            return false
        }
        else {
            setIsLoading(false);
            toast.error("Invalid Otp..")
            document.getElementById("password").focus();
            return false
        }
    }

    const sendotp = async (e) => {

        setIsLoading(true);


        const inputValue2 = document.getElementById("email").value.trim();
        if (inputValue2 === "") {
            document.getElementById("email").focus();
            setIsLoading(false);
            toast.error("Please Enter email address")
            return false
        }



        try {
            const nameQuery = `select * from shopmas where email = '${email}'`;
            const nameResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQuery)}`);
            if (nameResponse.data.length == 0) {
                toast.error("Email Not exist..."); setIsLoading(false); document.getElementById("email").focus();
                return false;
            }


            await axios.post(`${hostlink}/send-otp`, null, { params: { email } })
                .then((resp) => {
                    setMailotp(resp.data)
                })
            setIsLoading(false);
            toast.success("OTP sent successfully");
            let x = document.getElementById("veryfyotp")
            x.style.display = "unset"

            let y = document.getElementById("veryfyemail")
            y.style.display = "none"
        } catch (error) {

        }
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`$%^*+{}|;<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    };

    return (
        <div className="shop-create-container">
            <div className="background"></div>
            <div className='container p-3'>
                <div className=' text-start'>
                    <h1><i class="fa-solid fa-key"></i> Recovery Password..</h1> <br />
                </div>
                <div className='row' id='beforeveryfyemail'>
                    <div class="col-md-6" id='veryfyemail' >
                        <label className='font-monospace' class="form-label">Recovery email address</label>
                        <input type="email" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="email" />
                        <Link onClick={() => { sendotp() }} href="">veryfy</Link>
                        <br />
                    </div>
                    <div className='col-3' id='veryfyotp' style={{ display: "none" }} >
                        <label className='font-monospace' class="form-label"> Otp </label>
                        <input type="" onChange={(e) => { onChangevalue(e) }} name='password' value={password} class="form-control" id="password" /> <br />
                        <Link onClick={() => { Verifyotp() }} href="">Submit</Link>
                    </div>
                </div>

                <div className='row'>
                    <div class="col-md-6" id='newpassword' style={{ display: "none" }}>
                        <label className='font-monospace' class="form-label">New Password</label>
                        <input type="email" onChange={(e) => { onChangevalue(e) }} name='pass1' value={pass1} class="form-control" id="pass1" />
                        <label className='font-monospace' class="form-label">Conform Password</label>
                        <input type="email" onChange={(e) => { onChangevalue(e) }} name='pass2' value={pass2} class="form-control" id="pass2" />
                        <Link onClick={() => { updatepassword() }} href="">Submit</Link>
                        <br />
                    </div>
                </div>

            </div>
            <br />
            <marquee className='bg-black text-light p-1 mt-1' behavior="alternate" direction="right" >For more detail Contact on Mobile no : +91-9910496797, Email : info@crossrug.in </marquee>
            {/* <marquee className='bg-danger text-light p-1 mt-1' behavior="alternate" direction="left">ERP S/w, Retail Billing s/w, POS System, Restaurant Billing s/w, Payroll S/w, Account & inventory s/w</marquee>
            <marquee className='bg-black text-light p-1 mt-1' behavior="alternate" direction="left">Crossrug Technologies offering customized software solutions for your business according to your requirement. We have multiple software products with us with all required modules. (for more detail Contact on Mobile no : +91-9910496797, Email : info@crossrug.in)Crossrug Technologies offering customized software solutions for your business according to your requirement. We have multiple software products with us with all required modules. (for more detail Contact on Mobile no : +91-9910496797, Email : info@crossrug.in) </marquee> */}

            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div>
                    <img src="./please_wait.gif" alt="please wait creatig company..." style={{ width: "100%" }} />
                </div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    );
};

export default RecoveryyPassword;
