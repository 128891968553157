import React from 'react'
import css from "./app.module.css"
import Navbar from './Component/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ItemUnit_View from './Component/ItemUnit_View'
import Home from './Component/Home'
import ItemGroupMaster from './Component/ItemGroupMaster'
import KitchenMaster from './Component/KitchenMaster'
import DishTypeMaster from './Component/DishTypeMaster'
import ItemCompanyMaster from './Component/ItemCompanyMaster'
import DishHeadMaster from './Component/DishHeadMaster'
import FloorMaster from './Component/FloorMaster'
import RoomMaster from './Component/RoomMaster'
import TableMaster from './Component/TableMaster'
import ItemMaster from './Component/ItemMaster'
import Logbook from './Component/Logbook'
import RoomTypeMaster from './Component/RoomTypeMaster'
import CheckIn from './Component/CheckIn'
import CheckOut from './Component/CheckOut'
import SaleBill from './Component/SaleBill'
import MessageBox from './Component/MessageBox'
import SaleBillTrash from './Component/SaleBillTrash'
import SaleBillCancel from './Component/SaleBillCancel'
import Test from './Component/Test'
import Reservation from './Component/Reservation'
import Settings from './Component/Settings'
import Rpt_Room_status from './Report/Rpt_Room_status'
import Rpt_CheckInReport from './Report/Rpt_CheckInReport'
import Rpt_BillReport from './Report/Rpt_BillReport'
import Shop_Create from './Component/Shop_Create'
import AccountMaster from './Component/AccountMaster'
import RoomService from './Component/RoomService'
import FoodOrder from './Component/FoodOrder'
import KotView from './Component/KotView'
import RestBillView from './Component/RestBillView'
import Rpt_TableStatus from './Report/Rpt_TableStatus'
import Rpt_SaleReport from './Report/Rpt_SaleReport'
import RestBillViewTrash from './Component/RestBillViewTrash'
import RestBillViewCancelled from './Component/RestBillViewCancelled'
import PrintKot from './Component/PrintKot'
import BillPaymentRest from './Component/BillPaymentRest'
import RecoveryyPassword from './Component/RecoveryyPassword'
import Passwordchange from './Component/Passwordchange'
import PrinterSelector from './Component/PrinterSelector'
import { Printdata } from './Component/Printdata'
import TransferBill from './Component/TransferBill'
import EntryTrfBill from './Component/EntryTrfBill'
import BanquetHall from './Component/BanquetHall'
import BanquetBook from './Component/BanquetBook'
import Bom from './Component/Bom'
import WaiterMas from './Component/WaiterMas'
import CommentMas from './Component/CommentMas.jsx'
import TransferTable from './Component/TransferTable.jsx'
import MergeTable from './Component/MergeTable.jsx'
import Purchase from './Component/Purchase.jsx'
import DepartmentMas from './Component/DepartmentMas.jsx'
import Rpt_Stock_Report from './Report/Rpt_Stock_Report.jsx'
import Production from './Component/Production.jsx'
import Rpt_CostingReport from './Report/Rpt_CostingReport.jsx'
import StockTRF from './Component/StockTRF.jsx'
import Rpt_PurchaseReport from './Report/Rpt_PurchaseReport.jsx'
import LocationItemDisplay from './Component/LocationItemDisplay.jsx'
import ItemMasterStore from './Component/ItemMasterStore.jsx'
import LocationDishheadDisplay from './Component/LocationDishheadDisplay.jsx'
import LocationDishTypeDisplay from './Component/LocationDishTypeDisplay.jsx'


const App = () => {
  
  return (
    <div id={css.app}>
          <Navbar/>
          <BrowserRouter>
            <Routes>
              <Route path='/' Component={Home}></Route>
              <Route path='/ItemUnit_View' Component={ItemUnit_View}></Route>
              <Route path='/ItemGroupMaster' Component={ItemGroupMaster}></Route>
              <Route path='/KitchenMaster' Component={KitchenMaster}></Route>
              <Route path='/DishTypeMaster' Component={DishTypeMaster}/>
              <Route path='/ItemCompanyMaster' Component={ItemCompanyMaster}/>
              <Route path='/DishHeadMaster' Component={DishHeadMaster}/>
              <Route path='/FloorMaster' Component={FloorMaster}/>
              <Route path='/RoomMaster' Component={RoomMaster}/>
              <Route path='/TableMaster' Component={TableMaster}/>
              <Route path='/ItemMaster' Component={ItemMaster}/>
              <Route path='/Logbook' Component={Logbook}/>
              <Route path='/RoomTypeMaster' Component={RoomTypeMaster}/>
              <Route path='/CheckIn' Component={CheckIn}/>
              <Route path='/CheckOut' Component={CheckOut}/>
              <Route path='/SaleBill' Component={SaleBill}/>
              <Route path='/MessageBox' Component={MessageBox}/>
              <Route path='/SaleBillTrash' Component={SaleBillTrash}/>
              <Route path='/SaleBillCancel' Component={SaleBillCancel}/>
              <Route path='/Test' Component={Test}/>
              <Route path='/Reservation' element={<Reservation/>}/>
              <Route path='/Settings' element={<Settings/>}/>
              <Route path='/Rpt_Room_status' element={<Rpt_Room_status/>}/>
              <Route path='/Rpt_CheckInReport' element={<Rpt_CheckInReport/>}/>
              <Route path='/Rpt_BillReport' element={<Rpt_BillReport/>}/>
              <Route path='/Shop_Create' element={<Shop_Create/>}/>
              <Route path='/AccountMaster' element={<AccountMaster/>}/>
              <Route path='/RoomService' element={<RoomService/>}/>
              <Route path='/FoodOrder' element={<FoodOrder/>}/>
              <Route path='/KotView' element={<KotView/>}/>
              <Route path='/RestBillView' element={<RestBillView/>}/>
              <Route path='/Rpt_TableStatus' element={<Rpt_TableStatus/>}/>
              <Route path='/Rpt_SaleReport' element={<Rpt_SaleReport/>}/>
              <Route path='/RestBillViewTrash' element={<RestBillViewTrash/>}/>
              <Route path='/RestBillViewCancelled' element={<RestBillViewCancelled/>}/>
              <Route path='/PrintKot' element={<PrintKot/>}/>
              <Route path='/BillPaymentRest' element={<BillPaymentRest/>}/>
              <Route path='/RecoveryyPassword' element={<RecoveryyPassword/>}/>
              <Route path='/Passwordchange' element={<Passwordchange/>}/>
              <Route path='/PrinterSelector' element={<PrinterSelector/>}/>
              <Route path='/Printdata' element={<Printdata/>}/>
              <Route path='/TransferBill' element={<TransferBill/>}/>
              <Route path='/EntryTrfBill' element={<EntryTrfBill/>}/>
              <Route path='/BanquetHall' element={<BanquetHall/>}/>
              <Route path='/BanquetBook' element={<BanquetBook/>}/>                  
              <Route path='/Bom' element={<Bom/>}/>  
              <Route path='/WaiterMas' element={<WaiterMas/>}/>  
              <Route path='/CommentMas' element={<CommentMas/>}/> 
              <Route path='/TransferTable' element={<TransferTable/>}/>  
              <Route path='/MergeTable' element={<MergeTable/>}/>  
              <Route path='/Purchase' element={<Purchase/>}/>  
              <Route path='/DepartmentMas' element={<DepartmentMas/>}/>  
              <Route path='/Rpt_Stock_Report' element={<Rpt_Stock_Report/>}/>  
              <Route path='/Production' element={<Production/>}/>  
              <Route path='/Rpt_CostingReport' element={<Rpt_CostingReport/>}/>  
              <Route path='/StockTRF' element={<StockTRF/>}/>  
              <Route path='/Rpt_PurchaseReport' element={<Rpt_PurchaseReport/>}/>  
              <Route path='/LocationItemDisplay' element={<LocationItemDisplay/>}/>  
              <Route path='/ItemMasterStore' element={<ItemMasterStore/>}/>  
              <Route path='/LocationDishheadDisplay' element={<LocationDishheadDisplay/>}/>  
              <Route path='/LocationDishTypeDisplay' element={<LocationDishTypeDisplay/>}/>  
            </Routes>
          </BrowserRouter>     
          
    </div>
  )
}

export default App
