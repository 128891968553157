import React, { useEffect, useRef, useState } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';
const StockTRF = () => {
  const today = new Date();
  const roomCodeRef = useRef(null);
  const usershopFGcode = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].fgcode : '0';
  const usershopRWcode = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].rwcode : '0';
  const [isEditMode, setisEditMode] = useState(false);
  const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
  const scrollRef = useRef(null);
  const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
  const [editDeleteAPi, seteditDeleteAPi] = useState('');
  const [query, setQuery] = useState('');
  const [editblno, seteditblno] = useState(0);
  const [data, setData] = useState([]);
  const [accdata, setaccdata] = useState([])
  const [deptdata, setdeptdata] = useState([])
  const [srvdata, setsrvData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [listVisible, setListVisible] = useState(false);
  const inputRef = useRef(null);
  const usershopidref = localStorage.getItem('MYPCKARTUSER')
    ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].hotelshopid
    : '0';
  const queryCondition = usershopidref == 0
    ? `${usershopid}`
    : `${usershopidref}`;
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messagedata, setMessagedata] = useState({
    deleteid: "",
    deletemsg: ""
  })
  const { deleteid, deletemsg } = messagedata;


  const handleOpenModal = (e, flname) => {
    setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => { setIsModalOpen(false); };
  const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
  const usershopdeptcode = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].deptcode : '0'
  const queryConditionItem = usershopidref !== 0 ? `${usershopdeptcode}/itemmasterITG` : "itemmasterITG";
  const queryConditionItemRW = usershopidref !== 0 ? `${usershopdeptcode}/itemGRPLoc` : "itemGRP";
  const fetchData = async () => {
    const response = await fetch(`${hostlink}/${queryCondition}/item`);
    const jsonData = await response.json();
    setData(jsonData);
  };

  
  useEffect(() => {
    fetchData();
  }, []);

  const cmdEdit = async (shopvno) => {
    let x = document.getElementById("viewform")
    x.style.display = "None"
    let y = document.getElementById("newform")
    y.style.display = "block"
    setisEditMode(true);
    seteditblno(shopvno)
    await axios.get(`${hostlink}/stktrf/${usershopid}/${shopvno}`)
      .then((resp) => {
        console.log(resp.data[0])
        setitemdetailGrid(resp.data)
        setitemdetail(resp.data[0])
      })
  }
  const cmdDelete = (shopvno) => {

    axios.delete(`${hostlink}/stktrf/${usershopid}/${shopvno}`)
      .then((resp) => {
        toast.success("Delete Sucessfull")
        setTimeout(() => {
          setitemdetailGridRefresh(true)
          setIsModalOpen(false);
        }, 1000);
      })
      .catch((resp) => {
        console.log("Something Else")
      })
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    filterData(inputValue);
    setListVisible(true);
    setSelectedIndex(-1);
  };

  const filterData = (query) => {
    if (!query) {
      setFilteredData([]);
      return;
    }

    const filtered = data.filter(item => {
      return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
    }).slice(0, 5); // Limit to first 5 records after filtering

    setFilteredData(filtered);
  };
  const saveroomservice = async (isprint) => {

    if (vdate == "") {
      toast.error("Please Enter Bill Date...")
      document.getElementById("vdate").focus(); return false
    }
    if (fromdeptcode == 0) {
      toast.error("Please Select from Department...")
      document.getElementById("fromdeptcode").focus(); return false
    }
    if (todeptcode == 0) {
      toast.error("Please Select to Department...")
      document.getElementById("todeptcode").focus(); return false
    }

    if (todeptcode == fromdeptcode) {
      toast.error("Both Department is Same...")
      document.getElementById("todeptcode").focus(); return false
    }
    if (itemdetailGrid.length === 0) {
      toast.error("Nothing to Save. Add Item...")
      document.getElementById("cmdsave").disabled = false
      return false
    }

    for (let i = 0; i < itemdetailGrid.length; i++) {
      itemdetailGrid[i].id = '';
      itemdetailGrid[i].shopid = queryCondition;
      itemdetailGrid[i].totqty = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0);
      itemdetailGrid[i].vdate = vdate
      itemdetailGrid[i].fromdeptcode = fromdeptcode
      itemdetailGrid[i].todeptcode = todeptcode
    }

    if (isEditMode == true) {
      await axios.post(`${hostlink}/stktrfedit/${usershopid}/${editblno}`, itemdetailGrid)
        .then((resp) => {
          toast.success("Save Sucess..");
          setInterval(() => {
            window.location.assign("/StockTRF")
          }, 1000);
        }).catch((resp) => { console.log("Data Not Save..") })
    } else {
      await axios.post(`${hostlink}/stktrf`, itemdetailGrid)
        .then((resp) => {
          toast.success("Save Sucess..");
          setInterval(() => {
            window.location.assign("/StockTRF")
          }, 1000);
        }).catch((resp) => { console.log("Data Not Save..") })

    }
  }
  const handleSelectItem = (item, index) => {
    setSelectedItem(item);
    setQuery(item.itname);
    setListVisible(false);
    setSelectedIndex(index);
  };
  const [itemdetail, setitemdetail] = useState({ fromdeptcode:usershopdeptcode,todeptcode:0,fgqty:0,vdate: today.toISOString().split('T')[0], partyblno: "", rawcode: 0, rawname: "", barcode: "", qty: 1, acccode: 0, fgcode: 0, fgqty: 1, deptcode: 0, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
  const { rawcode, rawname, barcode, fgcode, todeptcode,fromdeptcode,fgqty, qty, rate, discperc, taxable, gst, gstamt, ittotal, vdate, partyblno, acccode, deptcode } = itemdetail;
  const [itemdetailGrid, setitemdetailGrid] = useState([]);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [itemdetailGrid]);

  useEffect(() => {
    
    axios.get(`${hostlink}/${queryCondition}/deptmas`)
      .then((Response) => { setdeptdata(Response.data) })
      // 
      axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,vdate,(select deptname from deptmas where id = stktrfmas.fromdeptcode) as fromdept,(select deptname from deptmas where id = stktrfmas.todeptcode) as todept,totqty from stktrfmas where shopid = ${queryCondition} and vdate between '${fromdate}' and '${todate}' and fromdeptcode = ${usershopdeptcode} group by shopvno,vdate`)
      .then((Response) => {
        setsrvData(Response.data)
        setitemdetailGridRefresh(false)
      })
      .catch((Response) => {
        console.log("Data Not Fatched")
      })
  }, [itemdetailGridRefresh]);
  const handleItemClick = (index) => {
    setSelectedIndex(index);
    handleSelectItem(filteredData[index], index);
    setQuery(filteredData[index].itname);
    setitemdetail({
      ...itemdetail,
      rawcode: filteredData[index].id,
      rawname: filteredData[index].itname,
      barcode: filteredData[index].barcode,
      qty: 1,
      rate: filteredData[index].restrate,
      discperc: filteredData[index].discperc,
      gst: filteredData[index].gst,
    });
    document.getElementById("qty").focus();
  };
  const handleKeyDown = (event) => {
    if (document.getElementById("inputname1111").value == "") {
      return false
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (event.key === 'Enter' && selectedIndex !== -1) {
      handleSelectItem(filteredData[selectedIndex], selectedIndex);
      setQuery(filteredData[selectedIndex].itname);
      setitemdetail({
        ...itemdetail,
        rawcode: filteredData[selectedIndex].id,
        rawname: filteredData[selectedIndex].itname,
        barcode: filteredData[selectedIndex].barcode,
        discperc: filteredData[selectedIndex].discperc,
        qty: 1,
        rate: filteredData[selectedIndex].restrate,
        gst: filteredData[selectedIndex].gst,
      });
      document.getElementById("qty").focus();
    }
  };

  const handleFocus = (e) => {
    // setTimeout(() => {
    //     e.target.select(); // Select the input value when it receives focus
    // }, 0);
  };
  const handleKeyDownnext = (event) => {
    if (event.keyCode === 39) {
      event.preventDefault(); // Prevent cursor from moving to the end
      event.target.setSelectionRange(0, event.target.value.length); // Select the input value
    }
    if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
      event.preventDefault(); // Prevent default Tab behavior
      const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
      if (currentIndex < interactiveElements.length - 1) {
        interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
      } else if (event.keyCode === 9) {
        interactiveElements[0].focus();
      }
    }
  };


  const [frmdata, setfrmdata] = useState({
    fromdate: today.toISOString().split('T')[0],
    todate: today.toISOString().split('T')[0],
    status: 3
  });
  const { fromdate, todate, status } = frmdata;
  const handleKeyDownFindItem = (event) => {

    const existingItem = data.find(item => item.barcode === barcode);
    if (existingItem) {
      console.log("Item found:", existingItem);

      setQuery(existingItem.itname)
      setitemdetail({
        ...itemdetail,
        rawcode: existingItem.id,
        rawname: existingItem.itname,
        barcode: existingItem.barcode,
        discperc: existingItem.discperc,
        qty: 1,
        rate: existingItem.restrate,
        gst: existingItem.gst,
      });
    } else {
      setitemdetail({
        ...itemdetail,
        id: 0,
      });
      return false
    }


    if (event.keyCode === 13 || event.keyCode === 9) {
      const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const currentIndex = Array.from(interactiveElements).indexOf(event.target);

      if (currentIndex < interactiveElements.length - 1) {
        interactiveElements[currentIndex + 1].focus();
        console.log("1")

      } else if (event.keyCode === 9) {
        interactiveElements[0].focus();
        console.log("2")
      }
    }
  };
  const [guestdata, setguestdata] = useState({ c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
  const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID, } = guestdata;
  const onChangevalueRoom = (e, f) => {
    const existingItem = Room.find(item => item[0] === f);
    if (existingItem) {
      console.log("find")

      const updatedItDetails = Room.map(roomdata => {
        if (roomdata[0] === f) {
          setguestdata({ ...guestdata, c_GCHID: roomdata[1], c_Grcodename: f, c_Gname: roomdata[2], c_GMob: roomdata[3], c_GAdd: roomdata[4], c_Grcode: roomdata[5] })
          toast.success("Conform Guest Detail..");
          document.getElementById("inputname1111").focus();
        }
      });

    } else {
      setguestdata({ ...guestdata, c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "" })
      toast.error("Select Room");
    }
  }

  const onChangevalue = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
    setitemdetail({ ...itemdetail, [name]: sanitizedValue });
  }
  const onChangevalueloaddata = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
    setfrmdata({ ...frmdata, [name]: sanitizedValue });
    setitemdetailGridRefresh(true)
  }
  const [isVisible, setIsVisible] = useState(false);

  const [Room, setRoom] = useState([])
  const [items, setItems] = useState([]);
  const handleDoubleClick = (itemId) => {
    const selectedItem = items.find(item => item.id === itemId);
    if (selectedItem) {
      alert(`Double-clicked on: ${selectedItem.itname}`);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  // Show button when user scrolls down 400px
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    // axios.get(`${hostlink}/${usershopid}/itemGRP/${usershopRWcode}`)
    axios.get(`${hostlink}/${queryCondition}/${queryConditionItemRW}/${usershopRWcode}`)
      .then((Response) => {
        setItems(Response.data)
      })
      .catch((Response) => {
        console.log("Data Not Fatched")
      })



    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const handleDeleteItem = async (id) => {
    const updatedItDetails = itemdetailGrid.filter(item => item.rawcode !== id);
    toast.success("Item deleted");
    setitemdetailGrid(updatedItDetails);
    document.getElementById("inputname1111").focus();
  };

  const handleAddItem = () => {

    if (rawcode == 0) {
      toast.error("Please Select Item..");
      document.getElementById("inputname1111").focus();
      return false
    }
    const existingItem = itemdetailGrid.find(item => item.rawcode === rawcode);
    if (existingItem) {
      const updatedItDetails = itemdetailGrid.map(item => {
        if (item.rawcode === rawcode) {
          return { ...item, qty: Number(Number(item.qty) + Number(qty)).toFixed(2) };
        }
        return item;
      });
      setitemdetailGrid(updatedItDetails);
      toast.success("quantity added..");
      setQuery('')
      setitemdetail({
        ...itemdetail,
        rawcode: 0,
        rawname: "",
        barcode: "",
        qty: "",
        discperc: 0,
        rate: "",
        gst: "",
      });
      document.getElementById("inputname1111").focus();
    } else {
      setitemdetailGrid([...itemdetailGrid, {
        rawcode: rawcode,
        rawname: rawname,
        barcode: barcode,
        discperc: discperc,
        qty: qty,
        rate: rate,
        gst: gst,
      }])
      toast.success("Item added..");
      setQuery('')
      setitemdetail({
        ...itemdetail,
        rawcode: 0,
        rawname: "",
        barcode: "",
        qty: "",
        rate: "",
        gst: "",
      });
      document.getElementById("inputname1111").focus();
    }
  };
  const cmdNew = () => {
    setisEditMode(false);
    seteditDeleteAPi('')
    let x = document.getElementById("viewform")
    x.style.display = "None"
    let y = document.getElementById("newform")
    y.style.display = "block"
  }
  const searchData = (e) => {
    const searchValue = document.getElementById("searchroom").value.trim();
    console.log(searchValue)
    // if (searchValue === "") {
    //     axios.get(`${hostlink}/getchehinroomservice/${usershopid}/${fromdate}/${todate}`)
    //         .then((Response) => {
    //             setsrvData(Response.data)
    //             setitemdetailGridRefresh(false)
    //         })
    //         .catch(() => { })
    // } else {
    //     axios.get(`${hostlink}/getchehinroomservice/search/${usershopid}/${fromdate}/${todate}/${searchValue}`)
    //         .then((Response) => {
    //             setsrvData(Response.data)
    //             setitemdetailGridRefresh(false)
    //         })
    //         .catch(() => { })
    // }
  }
  return (


    <div>
      <div id='viewform'>
        <div className='container p-2'>
          <div className='row p-1'>
            <div className='col-md-4 p-1'>
              <h5 className='font-monospace'> <i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> Stock Transfer</h5>
            </div>
            {/* <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
              <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search V No" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
            </div> */}

            <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
              </span>
              <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


              <span className="f-size-on-mobile align-self-end">
                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
              </span>
              <span>
                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
              </span>
            </div>


            <div className='col-md-2 text-end'>
              <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Create New </button>
            </div>
            <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="hide-on-mobile">Sr No</th>
                    <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Date</span> <span className='show-on-mobile'>Details</span></th>
                    <th scope="col" className="hide-on-mobile">From</th>
                    <th scope="col" className="hide-on-mobile">To</th>
                    <th scope="col" className="hide-on-mobile">Total Qty</th>
                    <th scope="col" className="f-size-on-mobile text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {srvdata.map((res, x) => (
                    <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0]}</td>
                      <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Bill Date :</b> </span> {res[1] ? new Date(res[1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                        <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                          {res[2] !== '' && (<>Bill No : {res[2]} <br /></>)}
                          {res[10] !== 0 && (<>Bill Amount : {res[3]} <br /></>)} <span style={{ color: "blue" }}> </span>
                        </div>
                      </td>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[2]}</td>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[3]}</td>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[4]}</td>

                      <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                        <Link to={"/StockTRF"} onClick={(e) => { cmdEdit(res[0]) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                        {/* <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res[11]) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp; */}
                        <Link className='btn btn-sm btn-danger' onClick={(e) => { handleOpenModal(res[0], res[0]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp;
                        {/* <Link className='btn btn-sm btn-danger' onClick={(e) => { cmdDelete(res[0]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp; */}

                        {/* onClick={(e) => { handleOpenModal(row.id, row.flname) }} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* New Service */}
      <div id='newform' style={{ display: "none" }}>
        <div className='container-fluid py-1 '>
          <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'white' }}>
            <div className="col-md-2 align-self-center"><h4> <i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> &nbsp; Stock Transfer</h4></div>
            <div className="col-2 text-end align-self-center"><label for="inputState" class="form-label"> </label> </div>
            {/* <input type="text" onChange={(e) => { onChangevalue(e) }} name='partyblno' value={partyblno} class="form-control" id="partyblno" style={{ width: "200px" }} /> <br /> */}
            <div className="col-1 text-start-0 align-self-end text-end"><label for="inputState" class="form-label"> Date :</label> </div>
            <input type="date" onChange={(e) => { onChangevalue(e) }} name='vdate' value={vdate} class="form-control" id="vdate" style={{ width: "200px" }} /> <br />


          </div>
          <p></p>
          <div className="row">
            <div className="col-md-12">

              <div className="row">
                <div className="col-md-2 text-end m-1"><label className='font-monospace' class="form-label">From Department : </label></div>
                <div className="col-md-2" >
                  <select id="fromdeptcode" name='fromdeptcode' value={fromdeptcode}  class="form-select">
                    <option selected key={0} value={0}>NA</option>
                    {deptdata.map((x) => {
                      return (
                        <option key={x.id} value={x.id}> {x.deptname} </option>
                      )
                    })}
                  </select>

                </div>
                <div className="col-1 text-end align-self-center"><label for="inputState" class="form-label"> To :</label> </div>
                <div className="col-2 text-start-0 align-self-center">
                  <select id="todeptcode" name='todeptcode' value={todeptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                    <option selected key={0} value={0}>NA</option>
                    {deptdata.map((x) => {
                      return (
                        <option key={x.id} value={x.id}> {x.deptname} </option>
                      )
                    })}
                  </select>
                </div>

                <div className="col-md-1 text-end" >
                  {/* <button onClick={(e) => checkBom(fgcode)} className='btn btn-outline-dark'> Generate </button> */}
                </div>
              </div>
              <br />
              <div className="row py-1">
                <div className="col-md-1">
                </div>
                <div className="col-md-4">
                  <label className='font-monospace' class="form-label">Item Name</label>
                  <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} /> <br />
                  {listVisible && filteredData.length > 0 && (
                    <ul style={{}}>
                      {filteredData.map((item, index) => (
                        <li
                          key={item.id}
                          onClick={() => handleSelectItem(item, index)}
                          onMouseDown={() => handleItemClick(index)}

                          className={index === selectedIndex ? 'selected' : ''}
                        >
                          {item.itname} - ₹{item.restrate}
                        </li>
                      ))}
                    </ul>
                  )}

                </div>
                <div className="col-md-2">
                  <label className='font-monospace' class="form-label">Barcode</label>
                  <input type="text" value={barcode} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='barcode' class="form-control" id="barcode" /> <br />
                </div>
                <div className="col-md-1">
                  <label className='font-monospace' class="form-label">Qty</label>
                  <input type="text" value={qty} name='qty' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="qty" /> <br />
                </div>
                {/* <div className="col-md-1">
                  <label className='font-monospace' class="form-label">Rate</label>
                  <input type="text" value={rate} name='rate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="rate" /> <br />
                </div>
                <div className="col-md-1">
                  <label className='font-monospace' class="form-label">Dis %</label>
                  <input type="text" value={discperc} name='discperc' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="discperc" /> <br />
                </div> */}
                <div id='addroomNew' className="col-md-2 text-center">
                  <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                  <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                </div>

              </div>

              <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                {itemdetailGrid.map((resp, index) => (
                  <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                    <h6 style={{ "color": "blue", borderBottom: "1px solid black" }}>Item : {resp.rawname}</h6>
                    <div className='col-9 text-start' style={{ fontSize: "15px", color: "black" }}>
                      <table style={{ "width": "100%" }}>
                        <tr><td>Qty </td><td>: {resp.qty}</td></tr>
                        <tr><td>Rate</td><td>: {resp.rate}</td></tr>
                        <tr><td>Gst {resp.gst} % </td><td>: {((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                        <tr><td>Total</td><td>: {(resp.qty * resp.rate) + ((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                      </table>
                    </div>
                    <div className='col-3 align-self-center'>
                      <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                    </div>

                  </div>
                ))}
              </div>

              <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                <div className='row text-left align-self-center p-1 m-0 '>
                  <div className='col-md-5'>Item Name</div>
                  <div className='col-md-3'>Barcode</div>
                  <div className='col-md-1 text-end'>Qty</div>
                  {/* <div className='col-md-1 text-end'>Rate</div> */}
                  {/* <div className='col-md-1 text-end'>Dis %</div>
                  <div className='col-md-1 text-end'>Dis Amt</div>
                  <div className='col-md-1 text-end'>Gst</div>
                  <div className='col-md-1 text-end'>Gst Amt</div>
                  <div className='col-md-1 text-end'>Amount</div> */}
                  <div className='col-md-1 text-end'>Action</div>
                </div>
              </div>
              <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                {itemdetailGrid.map((resp, index) => (
                  <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                    <div className='col-md-5 align-self-center'>{resp.rawname}</div>
                    <div className='col-md-3 align-self-center'>{resp.barcode}</div>
                    <div className='col-md-1 align-self-center text-end'>{Number(resp.qty).toFixed(2)}</div>
                    <div className='col-md-1 align-self-center text-end'>
                      <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                    </div>
                  </div>
                ))}
                <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                  <div className='col-md-7 align-self-center'></div>
                  <div className='col-md-1 align-self-center text-end'><b>Total :</b></div>
                  <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0)).toFixed(2)}</b></div>
                  
                  <div className='col-md-1 align-self-center  text-end'></div>
                  <div className='col-md-1 align-self-center'>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
            </div>
          </div>
        </div>
        <br /><br /><br /><br />
        <div className="parent-container">
          {/* Fixed element at the bottom */}
          <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

            <div className="container-fluid" >

              <div className="row">
                <div className="col-md-9 f-size-on-mobile text-end p-1" >
                  <p className='hide-on-mobile m-2'></p>
                  {/* <button id='cmdsave' onClick={() => { saveroomservice(1) }} class="btn btn-primary">Save & Print</button>  &nbsp; */}
                  <button id='cmdsave' onClick={() => { saveroomservice(0) }} class="btn btn-primary">Save</button>  &nbsp;
                  <a href='/StockTRF' class="btn btn-outline-primary">Cancel</a>
                </div>
                {/* Summary */}
                <div className="col-md-3 f-size-on-mobile ">
                  <div className='rounded'>
                    {/* <div className="row p-1 m-1 f-size-on-mobile">
                      <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: "blue" }}> Round-off :</div> <div style={{ fontFamily: 'arial', color: "blue", fontSize: '14px', fontWeight: 'bold' }} className="col-6 text-end">  {Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2)} </div>
                      <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))} </div>
                    </div> */}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
        <div><p>Are you sure you want to delete this <br /> Sr No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
      </Modal>
      <ToastContainer autoClose={1000}></ToastContainer>
    </div>
  )
}

export default StockTRF